<div style="margin-left: 5%; margin-right: 5%;">

  <div id="module_main_rmop">
    <br><br>

    <div class="grid">
      <div class="col-12 xl:col-2">
        <label for="month">Mes</label>
        <p-dropdown [options]="months" [(ngModel)]="selectMonth" placeholder="Seleccionar" autoWidth="false"
          [filter]="true" filterBy="nombre" optionValue="id" optionLabel="nombre" [showClear]="true"
          [style]="{ minWidth: '100%', width: '100%' }" [showClear]="true"></p-dropdown>
      </div>
      <div class="col-12 xl:col-2">
        <label for="month">Año</label>
        <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectYear" view="year" dateFormat="yy"
          [style]="{ minWidth: '100%', width: '100%' }" />
      </div>
      <div class="col-12 xl:col-2">
        <label for="city">Ciudad</label>
        <p-dropdown [options]="city" [(ngModel)]="selectCity" placeholder="Seleccionar" autoWidth="false"
          [filter]="true" filterBy="nombre" optionValue="id" optionLabel="nombre" [showClear]="true"
          [style]="{ minWidth: '100%', width: '100%' }" [showClear]="true">
        </p-dropdown>
      </div>
      <div class="col-12 xl:col-3">
        <label for="confunit">Configuracion Unidad</label>
        <p-dropdown [options]="unitconf" [(ngModel)]="selectConf" placeholder="Seleccionar" autoWidth="false"
          [filter]="true" filterBy="nombre" optionValue="clave_configuracionUnidad" optionLabel="nombre_config"
          [showClear]="true" [style]="{ minWidth: '100%', width: '100%' }" [showClear]="true">
        </p-dropdown>
      </div>
      <div class="col-12 xl:col-3" style="align-content: flex-end;">
        <div class="grid">
          <div class="col-6" style="padding-bottom: 0px;">
            <button pButton pRipple label="Buscar" id="button_search_unit" class="w-full" [loading]="loading"
              (click)="searchUnitAction();">
            </button>
          </div>
          <div class="col-6" style="padding-bottom: 0px;">
            <a id="btn_down_report_gnrl" pButton pRipple href="{{urlGeneral}}" type="button" label="Exportar"
              class="p-button-success" icon="pi pi-file-export" [style]="{ minWidth: '100%', width: '100%'}"
              style="text-decoration: none;" (click)="validateUrl($event)"></a>
          </div>
        </div>
      </div>

    </div>

    <br><br>

    <div class="grid">
      <div class="col-12 md:col-6 xl:col-4">
        <div class="card" style="background: #B39DDB; color:  whitesmoke; font-size: 90%;">
          <div style="text-align: center;">
            <b>TOTAL GENERAL</b>
          </div>
          <br>
          <div style="padding: 10px;">
            <table style="width: 100%; height: auto;">
              <tr>
                <td style="background: #B39DDB;">Operadores:</td>
                <td style="color: whitesmoke; text-align: right;"><b>{{lsResInfo.num_operadores != null ?
                    lsResInfo.num_operadores : ''}}</b></td>
                <ng-container *ngIf="lsResInfo.num_rendimiento_gen != null; else elseTotNumRendmGen">
                  <td style="text-align: right;">Rendimiento General:</td>
                </ng-container>
                <ng-template #elseTotNumRendmGen>
                  <td>Rendimiento General:</td>
                </ng-template>
                <td style="color: whitesmoke;"><b>{{lsResInfo.num_rendimiento_gen != null ?
                    lsResInfo.num_rendimiento_gen
                    +
                    ' km/l' : ''}}</b></td>
              </tr>
              <tr>
                <td>Consumo Combustible:</td>
                <td style="color: whitesmoke; text-align: right;"><b>{{lsResInfo.num_consumo_combustible != null ?
                    lsResInfo.num_consumo_combustible : ''}}</b></td>
                <ng-container *ngIf="lsResInfo.num_promedio_gen != null; else elseTotNumPromGen">
                  <td style="text-align: right;">Promedio General:</td>
                </ng-container>
                <ng-template #elseTotNumPromGen>
                  <td>Promedio General:</td>
                </ng-template>
                <td style="color: whitesmoke;"><b>{{lsResInfo.num_promedio_gen != null ? lsResInfo.num_promedio_gen +
                    ' km/l'
                    : ''}}</b></td>
              </tr>
              <tr>
                <td>KM Recorridos:</td>
                <td style="color: whitesmoke; text-align: right;"><b>{{lsResInfo.num_kilometros != null ?
                    lsResInfo.num_kilometros : ''}}</b></td>
                <ng-container *ngIf="lsResInfo.num_total_rutas != null; else elseTotRutes">
                  <td style="text-align: right;">Total rutas:</td>
                </ng-container>
                <ng-template #elseTotRutes>
                  <td>Total rutas:</td>
                </ng-template>
                <td style="color: whitesmoke;"><b>{{lsResInfo.num_total_rutas != null ? lsResInfo.num_total_rutas :
                    ''}}</b>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="card" style="background: #9FA8DA; color:  whitesmoke; font-size: 90%;">
          <div style="text-align: center;">
            <b>GASTOS GENERAL</b>
          </div>
          <br>
          <div style="padding: 10px;">
            <table style="width: 100%; height: auto;">
              <tr>
                <td style="width: 150px;">Combustible:</td>
                <td><b>{{lsResInfo.num_total_combustible != null ?
                    '$'+lsResInfo.num_total_combustible : '' }}</b></td>
                <td></td>
              </tr>
              <tr>
                <td style="width: 150px;">Casetas:</td>
                <td><b>{{lsResInfo.num_total_casetas != null ? '$'+lsResInfo.num_total_casetas
                    :
                    ''}}</b></td>
                <td></td>
              </tr>
              <tr>
                <td style="width: 150px;">Total:</td>
                <td><b>{{lsResInfo.num_total_com_cas != null ? '$'+lsResInfo.num_total_com_cas
                    :
                    ''}}</b></td>
                <td></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6 xl:col-4">
        <div class="card" style="background: #FFAB91; color:  whitesmoke; font-size: 90%;">
          <div style="text-align: center;">
            <b>OBJETIVO</b>
            <br>
            <b>{{lsResInfo.num_objetivo != null ? lsResInfo.num_objetivo + ' km/lt' : ''}}</b>
            <br>
            <br>
            <b style="color: black;">Rendimiento General:</b>
            <br>
            <b style="color: black;">{{lsResInfo.num_rendimiento_gen != null ?
              lsResInfo.num_rendimiento_gen
              +
              ' km/l' : ''}}</b>
          </div>
          <br>
        </div>
      </div>
    </div>

    <br><br>

    <div class="grid">
      <div class="col-12">
        <div class="card">
          <p-table #dt1 [value]="lsRmOper" [tableStyle]="{'min-width': '50rem'}"
            styleClass="p-datatable-sm p-datatable-striped" [globalFilterFields]="['nom_operador']"
            [resizableColumns]="false" [loading]="loading">
            <ng-template pTemplate="caption">
              <div class="flex justify-content-end">
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="applyFilterGlobal($event, 'contains')"
                    [style]="{ minWidth: '100%', width: '100%' }" placeholder="BUSCAR OPERADOR" />
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="nom_operador" style="min-width: 300px;">OPERADOR<p-sortIcon
                    field="nom_operador"></p-sortIcon>
                </th>
                <th pSortableColumn="num_total_litros" class="text-center" style="min-width: 180px;">TOTAL
                  LTS<p-sortIcon field="num_total_litros"></p-sortIcon></th>
                <th pSortableColumn="num_total_kilometros" class="text-center" style="min-width: 180px;">TOTAL
                  KMS<p-sortIcon field="num_total_kilometros"></p-sortIcon></th>
                <th pSortableColumn="num_rendimiento_gen" class="text-center" style="min-width: 180px;">
                  KM/LT<p-sortIcon field="num_rendimiento_gen"></p-sortIcon></th>
                <th pSortableColumn="num_metrica" class="text-center" style="min-width: 180px;">METRICA<p-sortIcon
                    field="num_metrica"></p-sortIcon></th>
                <th pSortableColumn="num_diferencia" class="text-center" style="min-width: 180px;">DIF<p-sortIcon
                    field="num_diferencia"></p-sortIcon></th>
                <th pSortableColumn="num_rutas" class="text-center" style="min-width: 180px;">RUTAS<p-sortIcon
                    field="num_rutas"></p-sortIcon></th>
                <th pSortableColumn="num_alcance" class="text-center" style="min-width: 180px;">ALCANCE<p-sortIcon
                    field="num_alcance"></p-sortIcon></th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-index>
              <tr>
                <ng-container *ngIf="data.num_diferencia < 0; else elseOperatorLess">
                  <td style="color: #F44336">{{data.nom_operador}}</td>
                </ng-container>
                <ng-template #elseOperatorLess>
                  <ng-container *ngIf="data.num_diferencia > 0; else elseOperatorMore">
                    <td style="color: #2E7D32">{{data.nom_operador}}</td>
                  </ng-container>
                  <ng-template #elseOperatorMore>
                    <td>{{data.nom_operador}}</td>
                  </ng-template>
                </ng-template>

                <td class="text-center">{{data.num_total_litros}}</td>
                <td class="text-center">{{data.num_total_kilometros}}</td>
                <td class="text-center" style="color: #4CAF50">{{data.num_rendimiento_gen}}</td>
                <td class="text-center">{{data.num_metrica}}</td>

                <ng-container *ngIf="data.num_diferencia < 0; else elseDifLess">
                  <td class="text-center" style="color: #F44336">{{data.num_diferencia}}</td>
                </ng-container>
                <ng-template #elseDifLess>
                  <ng-container *ngIf="data.num_diferencia > 0; else elseDifMore">
                    <td class="text-center" style="color: #4CAF50">{{data.num_diferencia}}</td>
                  </ng-container>
                  <ng-template #elseDifMore>
                    <td class="text-center">{{data.num_diferencia}}</td>
                  </ng-template>
                </ng-template>

                <td class="text-center">{{data.num_rutas}}</td>

                <ng-container *ngIf="data.num_diferencia < 0; else elseAlcLess">
                  <td class="text-center" style="color: #F44336">{{data.num_alcance}} %</td>
                </ng-container>
                <ng-template #elseAlcLess>
                  <ng-container *ngIf="data.num_diferencia > 0; else elseAlcMore">
                    <td class="text-center" style="color: #4CAF50">{{data.num_alcance}} %</td>
                  </ng-container>
                  <ng-template #elseAlcMore>
                    <td class="text-center">{{data.num_alcance}} %</td>
                  </ng-template>
                </ng-template>

                <td class="text-center">
                  <button pButton pRipple type="button" icon="pi pi-search"
                    class="p-button-rounded p-button-info p-button-text mr-2 mb-2"
                    (click)="chargeOpServInfo(data.jsn_serv)"></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <br /><br />
  </div>

  <div id="module_operator_rmop" style="display: none;">
    <br>

    <div class="flex justify-content-end">
      <button pButton pRipple type="button" icon="pi pi-times"
        class="p-button-rounded p-button-danger p-button-text mr-2 mb-2" (click)="hideServResume()"></button>
    </div>

    <br />

    <div class="flex justify-content-end">
      <a id="btn_down_report_oprt" pButton pRipple href="{{urlOperators}}" type="button" label="Exportar"
        class="p-button-success" icon="pi pi-file-export" style="text-decoration: none;"></a>
    </div>

    <br />

    <div class="grid">
      <div class="col-12">
        <div class="card">
          <p-table #dt1 [value]="lsRmOpRut" [tableStyle]="{'min-width': '50rem'}"
            styleClass="p-datatable-sm p-datatable-striped" [globalFilterFields]="['nom_ruta']" [scrollable]="true"
            scrollHeight="flex" scrollDirection="both" [resizableColumns]="false" [loading]="loading">
            <ng-template pTemplate="caption">
              <div class="flex justify-content-end">
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="applyFilterGlobal($event, 'contains')"
                    [style]="{ minWidth: '100%', width: '100%' }" placeholder="BUSCAR RUTA" />
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <ng-container *ngIf="!isMobile; else elseIsMobileHead">
                  <th style="min-width: 300px;" pFrozenColumn>RUTA</th>
                </ng-container>
                <ng-template #elseIsMobileHead>
                  <th style="min-width: 300px;">RUTA</th>
                </ng-template>
                <th style="min-width:200px;">FOLIO</th>
                <th style="min-width:100px;">CIUDAD</th>
                <th style="min-width:100px;">COLABORADOR</th>
                <th class="text-center" style="min-width:100px;">FECHA RUTA</th>
                <th class="text-center">CASETAS PASES</th>
                <th class="text-center">CASETAS BROXEL</th>
                <th class="text-center">CASETAS EDENRED</th>
                <th class="text-center">CASETAS TELEVIA</th>
                <th class="text-center">TOTAL CASETAS</th>
                <th class="text-center">COMBUSTIBLES SODEXO</th>
                <th class="text-center">COMBUSTIBLES BROXEL</th>
                <th class="text-center">COMBUSTIBLES OXXOGAS</th>
                <th class="text-center">TOTAL COMBUSTIBLES</th>
                <th class="text-center">KM INICIAL</th>
                <th class="text-center">KM FINAL</th>
                <th class="text-center">TOTAL LTS</th>
                <th class="text-center">TOTAL KMS</th>
                <th class="text-center">KM/LT RENDIMIENTO</th>
                <th class="text-center">ESTATUS RECURSOS</th>
                <th class="text-center">ESTATUS RUTA</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-index>
              <tr>
                <ng-container *ngIf="!isMobile; else elseIsMobileBody">
                  <td style="min-width: 300px;" pFrozenColumn><b>{{data.nom_ruta}}</b></td>
                </ng-container>
                <ng-template #elseIsMobileBody>
                  <td style="min-width: 300px;"><b>{{data.nom_ruta}}</b></td>
                </ng-template>
                <td style="color: green;"><b>{{data.num_folio_ruta}}</b></td>
                <td><b>{{data.nom_ciudad}}</b></td>
                <td class="text-center" style="color: blueviolet;"><b>{{data.nom_operador}}</b></td>
                <td style="color: blue;"><b>{{data.fec_ruta}}</b></td>
                <td class="text-center">{{formatCurrency(data.num_casetas_pases)}}</td>
                <td class="text-center">{{formatCurrency(data.num_casetas_broxel)}}</td>
                <td class="text-center">{{formatCurrency(data.num_casetas_edenred)}}</td>
                <td class="text-center">{{formatCurrency(data.num_casetas_televia)}}</td>
                <td class="text-center">{{formatCurrency(data.num_total_casetas)}}</td>
                <td class="text-center">{{formatCurrency(data.num_combustible_sodexo)}}</td>
                <td class="text-center">{{formatCurrency(data.num_combustible_broxel)}}</td>
                <td class="text-center">{{formatCurrency(data.num_combustible_oxxogas)}}</td>
                <td class="text-center">{{formatCurrency(data.num_total_combustible)}}</td>
                <td class="text-center">{{formatNumber(data.num_kim_inicial)}}</td>
                <td class="text-center">{{formatNumber(data.num_kim_final)}}</td>
                <td class="text-center">{{formatNumber(data.num_total_lts)}}</td>
                <td class="text-center">{{formatNumber(data.num_total_kms)}}</td>
                <td class="text-center" style="color: blue;"><b>{{formatNumber(data.num_ki_lts)}}</b></td>
                <td class="text-center"><b>{{data.nom_estatus_recursos}}</b></td>

                <ng-container *ngIf="data.nom_estatus_ruta == 'CANCELADO' else elseRouteCancelled">
                  <td style="color: orange;"><b>{{data.nom_estatus_ruta}}</b></td>
                </ng-container>
                <ng-template #elseRouteCancelled>
                  <td><b>{{data.nom_estatus_ruta}}</b></td>
                </ng-template>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <br /><br />
  </div>
</div>
