<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
  [rejectVisible]="false"></p-confirmDialog>

<div class="d-flex justify-content-center">
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-id-card mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">Gestion De Dispositivos</header>
          </div>
          <br />
          <br />
          <div style="display: inline-block;">
            <div class="row pb-2" style="display: flex; align-items: baseline;">
              <div class="col-md-4">
                <div class="card">
                  <p-dropdown [options]="lstUnits" [(ngModel)]="selectedUnit" autoWidth="false"
                    [style]="{'width':'100%'}" [filter]="true" filterBy="nombre" optionValue="id" optionLabel="nombre"
                    [showClear]="true" placeholder="Seleccione Numero Economico*"></p-dropdown>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card sinborde">
                  <button id="button_search_unit" type="button" class="btn btn-primary" [style]="{'width':'50%'}"
                    (click)="searchUnitAction();">
                    Buscar
                  </button>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card sinborde">
                  <!-- <button id="button_search_unit" type="button" pButton pRipple class="p-button-success ml-5" [style]="{'width':'50%'}"
                    (click)="exportarExcel();" >
                    <i class="pi pi-file-excel"> Exportar</i>
                  </button> -->
                  <p-toggleButton class="tbCustom" [style]="{'width':'50%'}"
                   [disabled]="selectedUnit == ''||selectedUnit == null||gestReportes" [(ngModel)]="gestReportes" 
                    onIcon="pi pi-file-excel" offIcon="pi pi-file-excel" 
                    onLabel="Exportar" offLabel="Exportar" styleClass="p-button-success ml-5"/>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div id="spinner_searching_unit" style="display: none;">
              <div style="display: flex; justify-content: center; align-items: center;">
                <p-progressSpinner ariaLabel="loading" />
              </div>
            </div>
            <div id="contenido_unidad_elementos" style="display: none;">
              <h6><b>Tipo Transporte:</b> {{unitType}}</h6>
              <h6><b>Numero Economico:</b> {{unitNumEco}}</h6>
              <h6><b>Ciudad:</b> {{unitCity}}</h6>

              <div class="card-content">
                <div class="row mt-3">
                  <p-table #dt1 [value]="lstUnitSelected" [tableStyle]="{'min-width': '50rem'}"
                    styleClass="p-datatable-sm p-datatable-striped" [rows]="100" [globalFilterFields]="['herramienta']"
                    [showCurrentPageReport]="true" [resizableColumns]="false"
                    currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros">
                    <ng-template pTemplate="caption">
                      <div class="flex justify-content-between">
                        <span class="p-input-icon-left p-ml-auto">
                          <i class="pi pi-search"></i>
                          <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                            placeholder="Buscar herramienta" />
                        </span>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                      <tr>
                        <th>Herramienta</th>
                        <th>Proveedor</th>
                        <th class="text-center">Instalada
                        <th class="text-center">Funcionamiento
                        <th class="text-center">Configurada
                        <th class="text-center">Acción
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-data let-index>
                      <tr>
                        <td>{{data.herramienta}}</td>
                        <td>{{data.proveedor}}</td>
                        <td class="text-center">
                          <ng-container *ngIf="data.clv_instalado != null; else templateclv_instalado">
                            <div>
                              <div>
                                <ng-container *ngIf="data.clv_instalado == 0; else tagNoFunc">
                                  <p-checkbox (onChange)="onChangeChekState(true, data.id, 1)" /> SI
                                  <p-tag severity="warning" value="No"></p-tag>
                                </ng-container>
                                <ng-template #tagNoFunc>
                                  <p-tag severity="success" value="Si"></p-tag>
                                  <p-checkbox (onChange)="onChangeChekState(false, data.id, 1)" /> NO
                                </ng-template>
                              </div>
                              <div style="padding-top: 5px;">
                                <p-tag icon="pi pi-user" pTooltip="{{data.usuario_instalado}}"
                                  tooltipPosition="left"></p-tag>&nbsp;
                                <p-tag icon="pi pi-calendar" severity="info" pTooltip="{{data.fecha_instalado}}"
                                  tooltipPosition="right"></p-tag>
                              </div>
                            </div>
                          </ng-container>
                          <ng-template #templateclv_instalado>
                            <div
                              class="background: var(--surface-card); padding: 2rem; border-radius: 10px; margin-bottom: 1rem; flex justify-content-center gap-3">
                              <div class="flex align-items-center">
                                <p-checkbox (onChange)="onChangeChekState(true, data.id, 1)" /> SI
                              </div>
                              <div class="flex align-items-center">
                                <p-checkbox (onChange)="onChangeChekState(false, data.id, 1)" /> NO
                              </div>
                            </div>
                          </ng-template>
                        </td>

                        <td class="text-center">
                          <ng-container *ngIf="data.clv_funcionamiento != null; else templateclv_funcionamiento">
                            <div>
                              <div>
                                <ng-container *ngIf="data.clv_funcionamiento == 0; else tagNoFunc">
                                  <p-checkbox (onChange)="onChangeChekState(true, data.id, 2)" /> SI
                                  <p-tag severity="warning" value="No"></p-tag>
                                </ng-container>
                                <ng-template #tagNoFunc>
                                  <p-tag severity="success" value="Si"></p-tag>
                                  <p-checkbox (onChange)="onChangeChekState(false, data.id, 2)" /> NO
                                </ng-template>
                              </div>
                              <div style="padding-top: 5px;">
                                <p-tag icon="pi pi-user" pTooltip="{{data.usuario_funcionamiento}}"
                                  tooltipPosition="left"></p-tag>&nbsp;
                                <p-tag icon="pi pi-calendar" severity="info" pTooltip="{{data.fecha_funcionamiento}}"
                                  tooltipPosition="right"></p-tag>
                              </div>
                            </div>
                          </ng-container>
                          <ng-template #templateclv_funcionamiento>
                            <div
                              class="background: var(--surface-card); padding: 2rem; border-radius: 10px; margin-bottom: 1rem; flex justify-content-center gap-3">
                              <div class="flex align-items-center">
                                <p-checkbox (onChange)="onChangeChekState(true, data.id, 2)" /> SI
                              </div>
                              <div class="flex align-items-center">
                                <p-checkbox (onChange)="onChangeChekState(false, data.id, 2)" /> NO
                              </div>
                            </div>
                          </ng-template>
                        </td>

                        <td class="text-center">
                          <ng-container *ngIf="data.clv_configuracion != null; else templateclv_configuracion">
                            <div>
                              <div>
                                <ng-container *ngIf="data.clv_configuracion == 0; else tagNoFunc">
                                  <p-checkbox (onChange)="onChangeChekState(true, data.id, 3)" /> SI
                                  <p-tag severity="warning" value="No"></p-tag>
                                </ng-container>
                                <ng-template #tagNoFunc>
                                  <p-tag severity="success" value="Si"></p-tag>
                                  <p-checkbox (onChange)="onChangeChekState(false, data.id, 3)" /> NO
                                </ng-template>
                              </div>
                              <div style="padding-top: 5px;">
                                <p-tag icon="pi pi-user" pTooltip="{{data.usuario_configuracion}}"
                                  tooltipPosition="left"></p-tag>&nbsp;
                                <p-tag icon="pi pi-calendar" severity="info" pTooltip="{{data.fecha_configuracion}}"
                                  tooltipPosition="right"></p-tag>
                              </div>
                            </div>
                          </ng-container>
                          <ng-template #templateclv_configuracion>
                            <div
                              class="background: var(--surface-card); padding: 2rem; border-radius: 10px; margin-bottom: 1rem; flex justify-content-center gap-3">
                              <div class="flex align-items-center">
                                <p-checkbox (onChange)="onChangeChekState(true, data.id, 3)" /> SI
                              </div>
                              <div class="flex align-items-center">
                                <p-checkbox (onChange)="onChangeChekState(false, data.id, 3)" /> NO
                              </div>
                            </div>
                          </ng-template>
                        </td>
                        <!--Bloque boton Edicion Fechas y visualización-->
                        <td class="text-center">
                          <ng-container>
                            <div class="row">
                              <div *ngIf="data.herr_vld">
                                <button pButton type="button" icon="pi pi-align-justify" 
                                  class="p-button-rounded p-button-warning p-button-text"
                                  pTooltip="Gestion Fechas" tooltipPosition="left"
                                  (click)="gestionFechas(data)">
                                </button>
                              </div>
                            </div>
                          </ng-container>
                            
                          <!-- <ng-container>
                            <div>
                              <div>
                                <ng-container *ngIf="data.clv_configuracion == 0; else tagNoFunc">
                                  <p-checkbox (onChange)="onChangeChekState(true, data.id, 3)" /> SI
                                  <p-tag severity="warning" value="No"></p-tag>
                                </ng-container>
                                <ng-template #tagNoFunc>
                                  <p-tag severity="success" value="Si"></p-tag>
                                  <p-checkbox (onChange)="onChangeChekState(false, data.id, 3)" /> NO
                                </ng-template>
                              </div>
                              <div style="padding-top: 5px;">
                                <p-tag icon="pi pi-user" pTooltip="{{data.usuario_configuracion}}"
                                  tooltipPosition="left"></p-tag>&nbsp;
                                <p-tag icon="pi pi-calendar" severity="info" pTooltip="{{data.fecha_configuracion}}"
                                  tooltipPosition="right"></p-tag>
                              </div>
                            </div>
                          </ng-container>
                          <ng-template #templateclv_configuracion>
                            <div
                              class="background: var(--surface-card); padding: 2rem; border-radius: 10px; margin-bottom: 1rem; flex justify-content-center gap-3">
                              <div class="flex align-items-center">
                                <p-checkbox (onChange)="onChangeChekState(true, data.id, 3)" /> SI
                              </div>
                              <div class="flex align-items-center">
                                <p-checkbox (onChange)="onChangeChekState(false, data.id, 3)" /> NO
                              </div>
                            </div>
                          </ng-template> -->
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
              <br /><br /><br />
            </div>
          </div>
          <p-dialog [resizable]="false" [draggable]="false" [(visible)]="confirmarSeleccionDispositivo"
            [style]="{ width: '50vw' }" (onHide)="ocultarDialogConfirmacion()">
            <ng-template pTemplate="content">
              <div class="mt-5">
                <h3 class="text-center">¿Estas Seguro que deseas guardar la Informacion?</h3>
              </div>
            </ng-template>
            <ng-template pTemplate="footer">
              <button pButton label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-text"
                (click)="ocultarDialogConfirmacion()"></button>
              <button pButton label="Continuar" icon="pi pi-check" class="p-button-success"
                (click)="updateEstadoDelDispositivo()"></button>
            </ng-template>
          </p-dialog>
          <p-dialog [resizable]="false" [draggable]="false" [(visible)]="gestFechas"
            [style]="{ width: '80vw' }" (onHide)="cancelGestFechas()">

            <!-- <ng-container *ngIf="data.clv_configuracion != null; else templateclv_configuracion">
              <div>
                <div>
                  <ng-container *ngIf="data.clv_configuracion == 0; else tagNoFunc">
                    <p-checkbox (onChange)="onChangeChekState(true, data.id, 3)" /> SI
                    <p-tag severity="warning" value="No"></p-tag>
                  </ng-container>
                  <ng-template #tagNoFunc>
                    <p-tag severity="success" value="Si"></p-tag>
                    <p-checkbox (onChange)="onChangeChekState(false, data.id, 3)" /> NO
                  </ng-template>
                </div>
                <div style="padding-top: 5px;">
                  <p-tag icon="pi pi-user" pTooltip="{{data.usuario_configuracion}}"
                    tooltipPosition="left"></p-tag>&nbsp;
                  <p-tag icon="pi pi-calendar" severity="info" pTooltip="{{data.fecha_configuracion}}"
                    tooltipPosition="right"></p-tag>
                </div>
              </div>-
            </ng-container>-->
            <ng-template pTemplate="header">
              Control Dispositivos
            </ng-template>
            <ng-template pTemplate="content">
              <div class="d-flex justify-content-start text-center texto-pesado  mt-5">
                <div class="col-md-6">Herramienta: {{selectedHerramientaFechas.herramienta}}</div>
                <div class="col-md-6">Proveedor: {{selectedHerramientaFechas.proveedor}}</div>
              </div>
              <div class="row d-flex justify-content-centert">
                <div class="col-md-6">
                  <ng-container *ngTemplateOutlet="templateTabFechas; context:{l:lstFechasGPS,t:1,a:'GPS',b:1}"> 
                  </ng-container>
                  <ng-container *ngTemplateOutlet="templateTabFechas; context:{l:lstFechasGPS_baja,t:1,a:'GPS',b:2}"> 
                  </ng-container>
                </div>
                <div class="col-md-6">
                  <ng-container *ngTemplateOutlet="templateTabFechas; context:{l:lstFechasCamara,t:2,a:'Camaras',b:1}"> 
                  </ng-container>
                  <ng-container *ngTemplateOutlet="templateTabFechas; context:{l:lstFechasCamara_baja,t:2,a:'Camaras',b:2}"> 
                  </ng-container>
                </div>
              </div>
            </ng-template>
            <ng-template #templateTabFechas let-products="l" let-Type="t" let-ContextHead="a" let-accion="b">
              <div class="mt-5 mb-5">
                <p-table [value]="products" class="dtCustom">
                  <ng-template pTemplate="caption">
                      <div class="flex align-items-center justify-content-between">
                          <div *ngIf="accion == 1">{{ContextHead+' Instalado'}}</div>
                          <div *ngIf="accion == 2">{{ContextHead+' Retirado'}}</div>
                          <div class="row mr-2" *ngIf="accion==1">
                            <div class="col">
                              <button pButton class="p-button-success btn-sm"
                              icon="pi pi-plus-circle"
                              pTooltip="Nuevo" tooltipPosition="top"
                              (click)="agregaFechas(selectedHerramientaFechas.id_herramienta,Type)"
                              [disabled]="products.length>=2"></button>
                            </div>
                            <div class="col">
                              <button pButton class="p-button-primary btn-sm"
                              icon="pi pi-refresh"
                              pTooltip="Revertir {{ContextHead}}"
                              (click)="revertChanges(selectedHerramientaFechas.id_herramienta,Type)"></button>
                            </div>
                          </div>
                      </div>
                  </ng-template>
                  <ng-template pTemplate="header" *ngIf="accion == 1">
                      <tr class="mt-5">
                          <th>Fecha de Instalación</th>
                          <th>Ubicación</th>
                          <th>Modelo</th>
                          <th>Serie</th>
                          <th *ngIf="accion == 1"> Acción </th>                      
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-product>
                      <tr>
                          <td>{{product.fecha | date:'dd/MM/YYYY'}}</td>
                          <td>{{ContextHead+'_'+product.id_tipo}}</td>
                          <td>{{product.modelo}}</td>
                          <td>{{product.serie}}</td>
                          <td *ngIf="accion == 1">
                            <button pButton class="p-button-danger btn-sm"
                            icon="pi pi-thumbs-down"
                            pTooltip="Retirar"
                            (click)="reemplazarEquipo(selectedHerramientaFechas.id_herramienta,Type,product.id_tipo)"></button>
                          </td>
                      </tr>
                  </ng-template>
                  <!-- <ng-template pTemplate="summary">
                      <div class="flex align-items-center justify-content-between">
                          In total there are {{products ? products.length : 0 }} products.
                      </div>
                  </ng-template> -->
                </p-table>
              </div>
            </ng-template>
            <ng-template pTemplate="footer">
              <button pButton label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-text"
              (click)="cancelGestFechas()"
              ></button>
              <button pButton label="Guardar" icon="pi pi-check" class="p-button-success" 
                [disabled]="!dirtyList" (click)="saveGestFechas()"
              ></button>
            </ng-template>
          </p-dialog>
          <p-dialog [resizable]="false" [draggable]="false" [(visible)]="addEquipo"
            [style]="{ width: '50vw' }" (onHide)="ocultarDialogAddEquipo()">
            <ng-template pTemplate="header">
              <div *ngIf="this.gest_id_tipo">Instalación</div>
              <div *ngIf="!this.gest_id_tipo">Reemplazo</div>
            </ng-template>
            <ng-template pTemplate="content">
              <!-- <div class="d-flex justify-content-start text-center texto-pesado  mt-5 mb-5">
                <div class="col-md-6">Herramienta: {{selectedHerramientaFechas.herramienta}}</div>
                <div class="col-md-6">Proveedor: {{selectedHerramientaFechas.proveedor}}</div>
              </div> -->
              <form [formGroup]="GestFechasForm">
                <div class="ml-5 mt-5">
                  <div class="row mt-2">
                      <div class="row d-flex justify-content-centert">
                        <div class="col-md-6" *ngIf="this.gest_id_tipo">
                          <div class="row g-0">
                            <div class="col-sm-6 mx-0">
                              <span class="input-group-addon"> 
                                <i class="pi pi-hashtag color-icon" style="font-size:1.5rem;"> Posicion:</i>
                              </span>
                            </div>                          
                            <div class="col-sm-4">
                              <p-dropdown formControlName="id_tipo" [options]="lstNumEquipo" optionLabel="nombre"
                                optionValue="id" placeholder="Posicion Equipo" [showClear]="true">
                              </p-dropdown>
                              <hr class="my-0">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row g-0">
                            <div class="col-sm-4 mx-0">
                              <span class="input-group-addon"> 
                                <i class="pi pi-calendar color-icon" style="font-size:1.5rem;"> Fecha:</i>
                              </span>
                            </div>                          
                            <div class="col-sm-6">
                              <input type="date" class="form-control sinborde" formControlName="fecha"
                                  pTooltip="Seleccione o teclee fecha" tooltipPosition="top">
                              <hr class="my-0">
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-10">
                      <div class="row">
                        <div class="form-group col-md-10">
                          <div class="row g-0">
                            <div class="col-sm-4 mx-0">
                              <span class="input-group-addon"> 
                                <i class="pi pi-hashtag color-icon" style="font-size:1.5rem;"> Modelo:</i>
                              </span>
                            </div>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="---_---_---" pTooltip="modelo"
                                formControlName="modelo">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-10">
                      <div class="row">
                        <div class="form-group col-md-10">
                          <div class="row g-0">
                            <div class="col-sm-4 mx-0">
                              <span class="input-group-addon"> 
                                <i class="pi pi-hashtag color-icon" style="font-size:1.5rem;"> Serie:</i>
                              </span>
                            </div>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" placeholder="---_---_---" pTooltip="Serie"
                                formControlName="serie">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ng-template>
            <ng-template pTemplate="footer">
              <button pButton label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-text"
                (click)="ocultarDialogAddEquipo()"
              ></button>
              <button pButton label="Aceptar" icon="pi pi-check" class="p-button-success" 
                [disabled]="!this.GestFechasForm.valid" (click)="aceptaGestionFecha()"
              ></button>
            </ng-template>
          </p-dialog>
          <p-dialog [resizable]="false" [draggable]="false" [(visible)]="gestReportes"
            [style]="{ width: '50vw',heigth: '35vh' }" (onHide)="gestReportes=false">
            <ng-template pTemplate="header">
              Reportes
            </ng-template>
            <ng-template pTemplate="content">
              <div class="m-5">
                <div class="row">
                    <div class="col-md-10">
                        <p class="texto-pesado">Gestion de Herramientas y Dispositivos(Unidad)</p>
                    </div>
                    <div class="col-md-2">
                        <button type="button" class="btn btn-outline-success" style="width: 100%;"
                            (click)="exportarExcel()">
                            Exportar <i class="pi pi-file-export"></i>
                        </button>
                    </div>
                </div>
                <hr class="style-two">
                <div class="row">
                  <div class="col-md-10">
                      <p class="texto-pesado">Herramientas GPS - Instalacion y Retiro(Unidad)</p>
                  </div>
                  <div class="col-md-2">
                      <button type="button" class="btn btn-outline-success" style="width: 100%;"
                          (click)="generarReporteFechaSeg()">
                          Exportar <i class="pi pi-file-export"></i>
                      </button>
                  </div>
                </div>
                <hr class="style-two">
                <div class="row d-flex justify-content-center">
                  <div id="spinner_reportes" style="display: none;">
                    <div style="display: flex; justify-content: center; align-items: center;">
                      <p-progressSpinner ariaLabel="loading" />
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="footer">
              <button pButton label="Cancelar" icon="pi pi-times" class="p-button-submit p-button-text"
               (click)="gestReportes=false"></button>
            </ng-template>
          </p-dialog>
        </div>
      </div>
    </div>
  </div>
</div>
