import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { IDefaultDataGDToolsUnitTransport, IDefaultLessDataGD, IDefaultDataUpdateCheck, ILstEquipos, IHerramienta, IEquiposSeg } from '../models/defaultGD';
import { GestionDispositivosToolsUnitTransportService } from '../services/gestionDispositivosToolsUnitTransport.service';
/*import xlsx from 'xlsx/xlsx';*/
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import ICredencialUsuario from '../models/credencial';
import { AuthService } from '../services/auth.service';
import { timeout } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-gestdisp',
  templateUrl: './gestdisp.component.html',
  styleUrls: ['./gestdisp.component.scss']
})

export class GestdispComponent {
  @ViewChild('dt1') dt!: Table;
  lstUnits: IDefaultLessDataGD[] = [];
  lstUnitSelected: IDefaultDataGDToolsUnitTransport[] = [];
  dspProc: boolean = false;
  selectedUnit: String = '';
  unitType: String = '';
  unitNumEco: String = '';
  unitCity: String = '';
  selectedCheckValue: Boolean = false;
  selectedCheckIdTool: Number = 0;
  selectedCheckIdToolType: Number = 0;
  confirmarSeleccionDispositivo: boolean = false;
  gestFechas: boolean = false;
  gestReportes: boolean = false;

  // GestFechasForm: FormGroup = new FormGroup({
  //   id: new FormControl(0,[Validators.required]),
  //   gps_uno_fecha: new FormControl(null, [Validators.required]),
  //   gps_uno_modelo: new FormControl('', [Validators.required]),
  //   gps_uno_serie: new FormControl('', [Validators.required]),
  //   gps_uno_fecha_baja: new FormControl(null, [Validators.required]),
  //   gps_uno_modelo_baja: new FormControl('', [Validators.required]),
  //   gps_uno_serie_baja: new FormControl('', [Validators.required]),
  //   gps_dos_fecha: new FormControl(null, [Validators.required]),
  //   gps_dos_modelo: new FormControl('', [Validators.required]),
  //   gps_dos_serie: new FormControl('', [Validators.required]),
  //   gps_dos_fecha_baja: new FormControl(null, [Validators.required]),
  //   gps_dos_modelo_baja: new FormControl('', [Validators.required]),
  //   gps_dos_serie_baja: new FormControl('', [Validators.required]),
  //   camara_uno_fecha: new FormControl(null, [Validators.required]),
  //   camara_uno_modelo: new FormControl('', [Validators.required]),
  //   camara_uno_serie: new FormControl('', [Validators.required]),
  //   camara_uno_fecha_baja: new FormControl(null, [Validators.required]),
  //   camara_uno_modelo_baja: new FormControl('', [Validators.required]),
  //   camara_uno_serie_baja: new FormControl('', [Validators.required]),
  //   camara_dos_fecha: new FormControl(null, [Validators.required]),
  //   camara_dos_modelo: new FormControl('', [Validators.required]),
  //   camara_dos_serie: new FormControl('', [Validators.required]),
  //   camara_dos_fecha_baja: new FormControl(null, [Validators.required]),
  //   camara_dos_modelo_baja: new FormControl('', [Validators.required]),
  //   camara_dos_serie_baja: new FormControl('', [Validators.required]),
  // });

  //id (id herramienta)
  //tipo (gps: 1,camara: 2)
  //id_tipo (ubicacion: 1 o 2)
  //accion (Alta 1 o Baja 2)
  addEquipo: boolean = false;
  gest_id_tipo: boolean = false;
  dirtyList: boolean = false;
  selectedHerramientaFechas: IHerramienta = {id_herramienta: 0, herramienta: '', proveedor: ''};
  lstFechasGPS: ILstEquipos[] = [];
  lstFechasGPS_baja: ILstEquipos[] = [];
  lstFechasCamara: ILstEquipos[] = [];
  lstFechasCamara_baja: ILstEquipos[] = [];
  lstNumEquipo: IDefaultLessDataGD[] = [{id:1,nombre:''},{id:2,nombre:''}];

  GestFechasForm: FormGroup = new FormGroup({
    id: new FormControl(0,[Validators.required]),
    id_tipo: new FormControl(0),
    tipo: new FormControl(0),
    accion: new FormControl(0),
    fecha: new FormControl(null, [Validators.required]),
    modelo: new FormControl('', [Validators.required,Validators.minLength(0),Validators.maxLength(20)]),
    serie: new FormControl('', [Validators.required,Validators.minLength(0),Validators.maxLength(30)])
  });

  //Permisos
  user: ICredencialUsuario = {};
  http: any;
  constructor(private router: Router,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private gestionDispositivosService: GestionDispositivosToolsUnitTransportService, private auth: AuthService) {
    this.cargarInformacionUsuario();
  }

  ngOnInit(): void {
    this.getListaDeUnidadesDisponibles();
  }

  showData() {
    const datosUnidad = document.getElementById('contenido_unidad_elementos')

    if (datosUnidad) {
      datosUnidad.style.display = "block"
    }
  }

  searchUnitAction() {
    if (this.selectedUnit == '' || this.selectedUnit == null) {
      alert('Debes seleccionar una unidad para continuar')
    } else {
      (document.getElementById('button_search_unit') as HTMLButtonElement).disabled = true
      this.progress()
      this.getListaDeHerramientasPorUnidad(this.selectedUnit)
    }
  }

  progress() {
    const datosUnidad = document.getElementById('spinner_searching_unit')

    if (datosUnidad) {
      if (datosUnidad.style.display === "none") {
        datosUnidad.style.display = "block";
      } else {
        datosUnidad.style.display = "none";
      }
    }
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  onChangeChekState(check: boolean, id: number, type: number) {
    this.selectedCheckIdTool = id
    this.selectedCheckValue = check
    this.selectedCheckIdToolType = type

    this.confirmarSeleccionDispositivo = true;
  }

  getListaDeUnidadesDisponibles() {
    this.gestionDispositivosService.getListadoHerramientasPorUnidadSoloNumeroEconomico().subscribe((resp) => {
      if (resp.status) {
        this.lstUnits = resp.data
      }

    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Herramientas');
      });
  }

  getListaDeHerramientasPorUnidad(id: String) {
    this.gestionDispositivosService.getListadoHerramientasPorUnidad(id).subscribe((resp) => {
      if (resp.status) {
        this.lstUnitSelected = resp.data;
        if (resp.data.length > 0) {
          this.unitType = resp.data[0].tipo
          this.unitNumEco = resp.data[0].num_economico
          this.unitCity = resp.data[0].ciudad
        }
      }
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> Al cargar el listado de herramientas seleccionadas de la unidad');
      });

    this.showData();
    this.progress();
    (document.getElementById('button_search_unit') as HTMLButtonElement).disabled = false
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Verifique la informacion.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  ocultarDialogConfirmacion() {
    this.confirmarSeleccionDispositivo = false;
  }

  updateEstadoDelDispositivo() {
    let data: IDefaultDataUpdateCheck = {
      id: this.selectedCheckIdTool,
      check: this.selectedCheckValue ? 1 : 0,
      user: this.user.cod == undefined ? '0001' : this.user.cod
    }

    if (this.selectedCheckIdToolType == 1) {
      this.gestionDispositivosService.updateActualizarHerramientaInstalada(data).subscribe((resp) => {
        if (resp.status) {
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se actualizo de forma correcta' });
          this.ocultarDialogConfirmacion()
          this.searchUnitAction()
        }
      },
        (error) => {
          this.mostrarDialogoInformativoSinInformacion('<b>Error</b> Al cargar el listado de herramientas seleccionadas de la unidad');
        });
    }

    if (this.selectedCheckIdToolType == 2) {
      this.gestionDispositivosService.updateActualizarHerramientaFuncionando(data).subscribe((resp) => {
        if (resp.status) {
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se actualizo de forma correcta' });
          this.ocultarDialogConfirmacion()
          this.searchUnitAction()
        }
      },
        (error) => {
          this.mostrarDialogoInformativoSinInformacion('<b>Error</b> Al cargar el listado de herramientas seleccionadas de la unidad');
        });
    }

    if (this.selectedCheckIdToolType == 3) {
      this.gestionDispositivosService.updateActualizarHerramientaConfigurada(data).subscribe((resp) => {
        if (resp.status) {
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se actualizo de forma correcta' });
          this.ocultarDialogConfirmacion()
          this.searchUnitAction()
        }
      },
        (error) => {
          this.mostrarDialogoInformativoSinInformacion('<b>Error</b> Al cargar el listado de herramientas seleccionadas de la unidad');
        });
    }
  }

  //Reporte
  exportarExcel() {
    if (!this.lstUnitSelected.length || typeof this.lstUnitSelected === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> Debe consultar un vehiculo para exportar(Excel)');
    } else {
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.lstUnitSelected.map((item) => {
          return {
            "#": item.id,
            NumeroEconomico: item.num_economico,
            Ciudad: item.ciudad,
            TipoTransporte: item.tipo,
            NombreHerramienta: item.herramienta,
            DescripcionHerramienta: item.herramienta_descripcion,

            DispositivoInstalado: item.clv_instalado,
            FechaConfirmacionInstalacion: item.fecha_instalado,
            UsuarioConfirmoInstalado: item.usuario_instalado,

            DispositivoFuncionando: item.clv_funcionamiento,
            FechaConfirmacionFuncionamiento: item.fecha_funcionamiento,
            UsuarioConfirmoFuncionamiento: item.usuario_funcionamiento,

            DispositivoConfigurado: item.clv_configuracion,
            FechaConfirmacionConfiguracion: item.fecha_configuracion,
            UsuarioConfirmoConfiguracion: item.usuario_configuracion
          }
        }));
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array"
        });
        this.saveAsExcelFile(excelBuffer, "GestionHerramientasYDispositivos_"+this.unitNumEco);
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_" + dateFormat + EXCEL_EXTENSION);
  }

  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  loadingReportProgress() {
    const reporteSpinner = document.getElementById('spinner_reportes')

    if (reporteSpinner) {
      if (reporteSpinner.style.display === "none") {
        reporteSpinner.style.display = "block";
      } else {
        reporteSpinner.style.display = "none";
      }
    }
  }

  genlstHerramientasSeg(data: IDefaultDataGDToolsUnitTransport)
  {
    if(data.gps_uno_fecha != null)
      this.lstFechasGPS.push({id_herramienta: data.id,tipo:1,id_tipo:1,accion:1,fecha:data.gps_uno_fecha,modelo:data.gps_uno_modelo,serie:data.gps_uno_serie});

    if(data.gps_dos_fecha != null)
      this.lstFechasGPS.push({id_herramienta: data.id,tipo:1,id_tipo:2,accion:1,fecha:data.gps_dos_fecha,modelo:data.gps_dos_modelo,serie:data.gps_dos_serie});

    if(data.gps_uno_fecha_baja != null)
      this.lstFechasGPS_baja.push({id_herramienta: data.id,tipo:1,id_tipo:1,accion:2,fecha:data.gps_uno_fecha_baja,modelo:data.gps_uno_modelo_baja,serie:data.gps_uno_serie_baja});

    if(data.gps_dos_fecha_baja != null)
      this.lstFechasGPS_baja.push({id_herramienta: data.id,tipo:1,id_tipo:2,accion:2,fecha:data.gps_dos_fecha_baja,modelo:data.gps_dos_modelo_baja,serie:data.gps_dos_serie_baja});

    if(data.camara_uno_fecha != null)
      this.lstFechasCamara.push({id_herramienta: data.id,tipo:2,id_tipo:1,accion:1,fecha:data.camara_uno_fecha,modelo:data.camara_uno_modelo,serie:data.camara_uno_serie});

    if(data.camara_dos_fecha != null)
      this.lstFechasCamara.push({id_herramienta: data.id,tipo:2,id_tipo:2,accion:1,fecha:data.camara_dos_fecha,modelo:data.camara_dos_modelo,serie:data.camara_dos_serie});

    if(data.camara_uno_fecha_baja != null)
      this.lstFechasCamara_baja.push({id_herramienta: data.id,tipo:2,id_tipo:1,accion:2,fecha:data.camara_uno_fecha_baja,modelo:data.camara_uno_modelo_baja,serie:data.camara_uno_serie_baja});

    if(data.camara_dos_fecha_baja != null)
      this.lstFechasCamara_baja.push({id_herramienta: data.id,tipo:2,id_tipo:2,accion:2,fecha:data.camara_dos_fecha_baja,modelo:data.camara_dos_modelo_baja,serie:data.camara_dos_serie_baja});
  }
  revertChanges(id:Number,Type: number)
  {
    this.GestFechasForm.reset();
    let orlst = this.lstUnitSelected.find(x => x.id == id)

    if(orlst!= undefined)
    {
      switch(Type){
        case 1:
          this.revGps(orlst);
          break;
        case 2:
          this.revCamara(orlst);
          break;
        default:
          break;

      }
    }
  }

  revGps(data: IDefaultDataGDToolsUnitTransport){
    this.lstFechasGPS = [];
    this.lstFechasGPS_baja = [];

    if(data.gps_uno_fecha != null)
      this.lstFechasGPS.push({id_herramienta: data.id,tipo:1,id_tipo:1,accion:1,fecha:data.gps_uno_fecha,modelo:data.gps_uno_modelo,serie:data.gps_uno_serie});

    if(data.gps_dos_fecha != null)
      this.lstFechasGPS.push({id_herramienta: data.id,tipo:1,id_tipo:2,accion:1,fecha:data.gps_dos_fecha,modelo:data.gps_dos_modelo,serie:data.gps_dos_serie});

    if(data.gps_uno_fecha_baja != null)
      this.lstFechasGPS_baja.push({id_herramienta: data.id,tipo:1,id_tipo:1,accion:2,fecha:data.gps_uno_fecha_baja,modelo:data.gps_uno_modelo_baja,serie:data.gps_uno_serie_baja});

    if(data.gps_dos_fecha_baja != null)
      this.lstFechasGPS_baja.push({id_herramienta: data.id,tipo:1,id_tipo:2,accion:2,fecha:data.gps_dos_fecha_baja,modelo:data.gps_dos_modelo_baja,serie:data.gps_dos_serie_baja});
  }

  revCamara(data: IDefaultDataGDToolsUnitTransport){
    this.lstFechasCamara = [];
    this.lstFechasCamara_baja = [];

    if(data.camara_uno_fecha != null)
      this.lstFechasCamara.push({id_herramienta: data.id,tipo:2,id_tipo:1,accion:1,fecha:data.camara_uno_fecha,modelo:data.camara_uno_modelo,serie:data.camara_uno_serie});

    if(data.camara_dos_fecha != null)
      this.lstFechasCamara.push({id_herramienta: data.id,tipo:2,id_tipo:2,accion:1,fecha:data.camara_dos_fecha,modelo:data.camara_dos_modelo,serie:data.camara_dos_serie});

    if(data.camara_uno_fecha_baja != null)
      this.lstFechasCamara_baja.push({id_herramienta: data.id,tipo:2,id_tipo:1,accion:2,fecha:data.camara_uno_fecha_baja,modelo:data.camara_uno_modelo_baja,serie:data.camara_uno_serie_baja});

    if(data.camara_dos_fecha_baja != null)
      this.lstFechasCamara_baja.push({id_herramienta: data.id,tipo:2,id_tipo:2,accion:2,fecha:data.camara_dos_fecha_baja,modelo:data.camara_dos_modelo_baja,serie:data.camara_dos_serie_baja});
  }

  confirmRetiro(data: ILstEquipos)
  {
    let tipo = [{id:1,nombre:'GPS.'},{id:2,nombre:'Camara.'}]
    let t = tipo.find(x=> x.id == data.tipo)?.nombre;
    let text:string = '';
    if(typeof t === 'undefined')
      text = '';
    else
      text = t;

    this.mostrarDialogoConfirmRetira('<b>Confirma</b> desea <b>Retirar</b> '+t,data);
  }

  mostrarDialogoConfirmRetira(mensaje_usuario: string,data: ILstEquipos) {
    let mensaje_completo = mensaje_usuario + '. <b>Verifique la informacion.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirma Retiro', detail: 'Usuario informado de la situación.' });
        this.retiraSeg(data);
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  gestionFechas(data: IDefaultDataGDToolsUnitTransport)
  {
    this.gestFechas = true;
    this.lstFechasGPS = [];
    this.lstFechasCamara = [];
    this.selectedHerramientaFechas = { id_herramienta: data.id,herramienta:data.herramienta,proveedor:data.proveedor };

    this.genlstHerramientasSeg(data);
  }

  retiraSeg(data: ILstEquipos)
  {
    switch(data.tipo)
    {
        case 1:
            this.retiraGPS(data);
          break;
        case 2:
            this.retiraCamara(data);
          break;
        default:
            this.messageService.add({ key: 'msj', severity: 'warning', summary: 'Atención', detail: 'Favor de recargar la pagina.' });
          break;
    }
  }

  retiraGPS(data: ILstEquipos)
  {
    if(this.lstFechasGPS_baja.length < 2)
      this.lstFechasGPS_baja.push(data);
    else
    {
      this.lstFechasGPS_baja = this.lstFechasGPS_baja.filter(x => x.id_tipo);
      this.retiraGPS(data);
    }
  }

  retiraCamara(data: ILstEquipos)
  {
    if(this.lstFechasCamara_baja.length < 2)
      this.lstFechasCamara_baja.push(data);
    else
    {
      this.lstFechasCamara_baja = this.lstFechasCamara_baja.filter(x => x.id_tipo);
      this.retiraCamara(data);
    }
  }

  generarReporteFechaSeg(){
    if (this.selectedUnit == '' || this.selectedUnit == null) {
      alert('Debes seleccionar una unidad para continuar');
    } else {
      var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
      var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
      var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
      var dateFormat = getDay + "-" + getMonth + "-" + getYear;
      // let filename = 'Report.xlsx';
      // const token = 'my JWT';
      // const headers = new HttpHeaders().set('authorization','Bearer '+token);

      // this.http.get('https://beta.gestiondispositivos.sw-hmp.com/api/v1/tool/unit/report-units?id='+this.selectedUnit
      // ,{headers, responseType: 'blob' as 'json'}).subscribe(
      //   (response: any) =>{
      //       let dataType = response.type;
      //       let binaryData = [];
      //       binaryData.push(response);
      //       let downloadLink = document.createElement('a');
      //       downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      //       if (filename)
      //           downloadLink.setAttribute('download', filename);
      //       document.body.appendChild(downloadLink);
      //       downloadLink.click();

      this.loadingReportProgress();
      this.gestionDispositivosService.getReporteFechasSegHerramienta(this.selectedUnit).subscribe((resp: HttpResponse<Blob>) => {
        // const contentDisposition: string | null = resp.headers.get('Content-Disposition');
        // const r = /(?:filename=")(.+)(?:;")/
        // r.exec(contentDisposition);
        /*
         var downloadUrl= URL.createObjectURL(blob)
         window.open(downloadUrl, "_blank");
         */
        let eco = this.lstUnits.find(x => x.id == Number.parseInt(this.selectedUnit.toString()))?.nombre;
        let num_economico = typeof eco !== 'undefined' ? eco.toString():'NumeroEconomico';
        if(resp.body != null)
        {
          // Doing it this way allows you to name the file
          var link=document.createElement('a');
          link.href=window.URL.createObjectURL(resp.body);
          link.download="ControlDispositivos_InstalacionYRetiro_"+num_economico+"_"+dateFormat+".xlsx";
          link.click();
          this.loadingReportProgress();
        }
      },((error) =>{
          this.messageService.add({ key: 'msj', severity: 'warning', summary: 'Atención', detail: 'Favor de recargar la pagina.' });
          this.loadingReportProgress();
      }));
    }
  }

  agregaFechas(id:Number, type: number)
  {
    let cant = type == 1 ? this.lstFechasGPS.length : this.lstFechasCamara.length;
    let tipo = [{id:1,nombre:'GPS'},{id:2,nombre:'Camara'}]
    let t = tipo.find(x=> x.id == type)?.nombre;
    let text:string = '';

    if(typeof t === 'undefined')
      text = '';
    else
      text = t;

    if(cant >= 2)
    {
      this.mostrarDialogoInformativoSinInformacion('La Herramienta ya cuenta con el numero maximo de '+text+'. Favor de realizar un Retiro.');
    }
    else{
      //let idlst = cant == 0 ? 1 : type == 1 ? this.lstFechasGPS[0].id_tipo : this.lstFechasCamara[0].id_tipo;
      // let id_Exists = type == 1 ? this.lstFechasGPS.length : this.lstFechasCamara.length;

        this.lstNumEquipo.forEach(x => x.nombre = text+'-'+x.id)
        this.gest_id_tipo = true;
        this.GestFechasForm.get('id')?.setValue(id);
        this.GestFechasForm.get('id_tipo')?.setValue(null);
        this.GestFechasForm.get('tipo')?.setValue(type);
        this.GestFechasForm.get('accion')?.setValue(1);
        this.addEquipo = true;

    }
  };

  reemplazarEquipo(id:Number, type: number,id_type: Number){
    this.gest_id_tipo = false;

    this.GestFechasForm.get('id')?.setValue(id);
    this.GestFechasForm.get('id_tipo')?.setValue(id_type);
    this.GestFechasForm.get('tipo')?.setValue(type);
    this.GestFechasForm.get('accion')?.setValue(2);
    this.addEquipo = true;
  };
  // if(cant >= 2)
  //   {
  //     let tipo = [{id:1,nombre:'GPS.'},{id:2,nombre:'Camara.'}]
  //     let t = tipo.find(x=> x.id == type)?.nombre;
  //     let text:string = '';

  //     if(typeof t === 'undefined')
  //       text = '';
  //     else
  //       text = t;

  //     this.mostrarDialogoInformativoSinInformacion('La Herramienta ya cuenta con el numero maximo de '+text+' Favor de realizar un Retiro.');
  //   }
  //   else{
  //     let idlst = cant == 0 ? 1 : type == 1 ? this.lstFechasGPS[0].id_tipo : this.lstFechasCamara[0].id_tipo;
  //     let id_Exists = type == 1 ? this.lstFechasGPS.length : this.lstFechasCamara.length;

  //     this.GestFechasForm.get('id')?.setValue(id);
  //     this.GestFechasForm.get('id_tipo')?.setValue(idlst == 0 ? 1 : idlst == 1 ? 2 : 1);
  //     this.GestFechasForm.get('tipo')?.setValue(type);
  //     this.addEquipo = true;
  //   }
  aceptaGestionFecha(){
    this.addEquipo = false;
    this.dirtyList = true;

    let accion = this.GestFechasForm.get('accion')?.value;
    let tipo = this.GestFechasForm.get('tipo')?.value;

    if(tipo == 1)
    {
      let x = this.lstFechasGPS.findIndex( x => x.id_tipo == this.GestFechasForm.get('id_tipo')?.value);
      if(accion == 1 && x == -1)
      {
        this.lstFechasGPS.push({
          id_herramienta: this.GestFechasForm.get('id')?.value
          ,id_tipo: this.GestFechasForm.get('id_tipo')?.value
          ,tipo: 1
          ,accion:1
          ,modelo: this.GestFechasForm.get('modelo')?.value
          ,fecha:this.GestFechasForm.get('fecha')?.value
          ,serie:this.GestFechasForm.get('serie')?.value})
      }
      else
      {
        let upBaja = this.lstFechasGPS[x];

        if(typeof upBaja !== 'undefined')
        {
          let indx = this.lstFechasGPS_baja.findIndex(x => x.id_tipo == upBaja.id_tipo);

          if(indx != -1)
          {
            this.lstFechasGPS_baja[indx].modelo = upBaja.modelo;
            this.lstFechasGPS_baja[indx].serie = upBaja.serie;
            this.lstFechasGPS_baja[indx].fecha = upBaja.fecha;

            this.lstFechasGPS[x].modelo = this.GestFechasForm.get('modelo')?.value;
            this.lstFechasGPS[x].serie = this.GestFechasForm.get('serie')?.value;
            this.lstFechasGPS[x].fecha = this.GestFechasForm.get('fecha')?.value;
          }else
          {
            this.lstFechasGPS_baja.push({
              id_herramienta: upBaja.id_herramienta
              ,id_tipo: upBaja.id_tipo
              ,tipo: 1
              ,accion: 2
              ,modelo: upBaja.modelo
              ,fecha: new Date
              ,serie: upBaja.serie})

            this.lstFechasGPS[x].modelo = this.GestFechasForm.get('modelo')?.value;
            this.lstFechasGPS[x].serie = this.GestFechasForm.get('serie')?.value;
            this.lstFechasGPS[x].fecha = this.GestFechasForm.get('fecha')?.value;
          }
        }
      }
    }else
    {
      let x = this.lstFechasCamara.findIndex( x => x.id_tipo == this.GestFechasForm.get('id_tipo')?.value);
      if(accion == 1 && x == -1)
      {
        this.lstFechasCamara.push({
          id_herramienta: this.GestFechasForm.get('id')?.value
          ,id_tipo: this.GestFechasForm.get('id_tipo')?.value
          ,tipo: 1
          ,accion:1
          ,modelo: this.GestFechasForm.get('modelo')?.value
          ,fecha:this.GestFechasForm.get('fecha')?.value
          ,serie:this.GestFechasForm.get('serie')?.value})
      }
      else
      {
        let upBaja = this.lstFechasCamara[x];

        if(typeof upBaja !== 'undefined')
        {
          let indx = this.lstFechasCamara_baja.findIndex(x => x.id_tipo == upBaja.id_tipo);

          if(indx != -1)
          {
            this.lstFechasCamara_baja[indx].modelo = upBaja.modelo;
            this.lstFechasCamara_baja[indx].serie = upBaja.serie;
            this.lstFechasCamara_baja[indx].fecha = upBaja.fecha;

            this.lstFechasCamara[x].modelo = this.GestFechasForm.get('modelo')?.value;
            this.lstFechasCamara[x].serie = this.GestFechasForm.get('serie')?.value;
            this.lstFechasCamara[x].fecha = this.GestFechasForm.get('fecha')?.value;
          }else
          {
            this.lstFechasCamara_baja.push({
              id_herramienta: upBaja.id_herramienta
              ,id_tipo: upBaja.id_tipo
              ,tipo: 1
              ,accion: 2
              ,modelo: upBaja.modelo
              ,fecha: new Date
              ,serie: upBaja.serie})

            this.lstFechasCamara[x].modelo = this.GestFechasForm.get('modelo')?.value;
            this.lstFechasCamara[x].serie = this.GestFechasForm.get('serie')?.value;
            this.lstFechasCamara[x].fecha = this.GestFechasForm.get('fecha')?.value;
          }
        }
      }
    }
  }

  ocultarDialogAddEquipo(){
    this.addEquipo= false;
    this.GestFechasForm.reset();
  }

  saveGestFechas()
  {
    this.gestFechas = false;
    let cantGps= this.lstFechasGPS.length;
    let cantGps_Retiro= this.lstFechasGPS_baja.length;
    let cantCam = this.lstFechasCamara.length;
    let cantCam_Retiro= this.lstFechasCamara_baja.length;

    let data: IEquiposSeg ={
      id: this.selectedHerramientaFechas.id_herramienta,
      gps_uno_fecha: cantGps>=1 ? this.lstFechasGPS[0].fecha:null,
      gps_uno_modelo: cantGps>=1 ? this.lstFechasGPS[0].modelo: '',
      gps_uno_serie: cantGps>=1 ? this.lstFechasGPS[0].serie: '',
      gps_uno_fecha_baja: cantGps_Retiro>=1 ? this.lstFechasGPS_baja[0].fecha:null,
      gps_uno_modelo_baja: cantGps_Retiro>=1 ? this.lstFechasGPS_baja[0].modelo : '',
      gps_uno_serie_baja: cantGps_Retiro>=1 ? this.lstFechasGPS_baja[0].serie:'',
      gps_dos_fecha: cantGps>=2 ? this.lstFechasGPS[1].fecha : null,
      gps_dos_modelo: cantGps>=2 ? this.lstFechasGPS[1].modelo : '',
      gps_dos_serie: cantGps>=2 ? this.lstFechasGPS[1].serie : '',
      gps_dos_fecha_baja: cantGps_Retiro>=2 ? this.lstFechasGPS_baja[1].fecha : null,
      gps_dos_modelo_baja: cantGps_Retiro>=2 ? this.lstFechasGPS_baja[1].modelo : '',
      gps_dos_serie_baja: cantGps_Retiro>=2 ? this.lstFechasGPS_baja[1].serie : '',
      camara_uno_fecha: cantCam>=1 ? this.lstFechasCamara[0].fecha:null,
      camara_uno_modelo: cantCam>=1 ? this.lstFechasCamara[0].modelo:'',
      camara_uno_serie: cantCam>=1 ? this.lstFechasCamara[0].serie:'',
      camara_uno_fecha_baja: cantCam_Retiro>=1 ? this.lstFechasCamara_baja[0].fecha:null,
      camara_uno_modelo_baja: cantCam_Retiro>=1 ? this.lstFechasCamara_baja[0].modelo:'',
      camara_uno_serie_baja: cantCam_Retiro>=1 ? this.lstFechasCamara_baja[0].serie:'',
      camara_dos_fecha: cantCam>=2 ? this.lstFechasCamara[1].fecha:null,
      camara_dos_modelo: cantCam>=2 ? this.lstFechasCamara[1].modelo:'',
      camara_dos_serie: cantCam>=2 ? this.lstFechasCamara[1].serie:'',
      camara_dos_fecha_baja: cantCam_Retiro>=2 ? this.lstFechasCamara_baja[1].fecha:null,
      camara_dos_modelo_baja: cantCam_Retiro>=2 ? this.lstFechasCamara_baja[1].modelo:'',
      camara_dos_serie_baja: cantCam_Retiro>=2 ? this.lstFechasCamara_baja[1].serie:''
    }

    this.gestionDispositivosService.saveFechasSegHerramienta(data).subscribe((resp) => {
      if (resp.status) {
        this.cancelGestFechas();
        this.gestFechas = false;
        (document.getElementById('button_search_unit') as HTMLButtonElement).disabled = true
        this.progress();
        this.getListaDeHerramientasPorUnidad(this.selectedUnit);
      }
      else{
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> Guardar el control de dispositivos.');
      }
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> Guardar el control de dispositivos.');
      });
  }

  cancelGestFechas()
  {
    this.gestFechas = false;
    this.dirtyList = false;

    this.selectedHerramientaFechas={id_herramienta: 0, herramienta: '', proveedor: ''};
    this.lstFechasGPS = [];
    this.lstFechasGPS_baja = [];
    this.lstFechasCamara = [];
    this.lstFechasCamara_baja = [];
    this.GestFechasForm.reset();
  }
}
