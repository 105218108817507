<p-toast position="top-right" key="msj"></p-toast>
<p-toast position="top-center" key="confirm" (onClose)="cancelarMensajeConfirmacion()" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{message.summary}}</h4>
        <p>{{message.detail}}</p>
      </div>
      <div class="grid p-fluid">
        <div class="col-6">
          <button type="button" pButton (click)="cancelarMensajeConfirmacion()" label="Cancelar"
            class="p-button-secondary"></button>
        </div>
        <div class="col-6">
          <button type="button" pButton (click)="confirmarMensaje()" label="Aceptar" class="p-button-success"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
  rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
  [rejectVisible]="false"></p-confirmDialog>

<div class="d-flex justify-content-center">
  <div class="container-fluid" style="width: 95%;">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card card-shadow sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-map-marker mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">Gestión de
              <div style="color:#1e5a9c; display: inline;"> Folios de Unidades</div>
            </header>

          </div>
          <div class="card-content">
            <div class="row mt-3">
              <p-table #dt1 [value]="lstFolioUnidad" [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-sm"
                [scrollable]="true" scrollHeight="500px" responsiveLayout="scroll" [globalFilterFields]="['num_economico','desc_placaUnidad','vin','desc_ciudad',
                'desc_unidad_transporte','desc_tipo_unidad']" [paginator]="true" [rows]="50"
                [rowsPerPageOptions]="[25,50,75,100,150]" [showCurrentPageReport]="true" [resizableColumns]="false"
                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros" [loading]="loading"
                sortField="desc_ciudad">
                <ng-template pTemplate="caption">
                  <div class="row">
                    <div class="flex justify-content-between align-items-center w-100">

                      <div class="col-md-4 flex justify-content-start ml-5">
                        <div class="col-md-6 ml-5">
                          <button type="button" class="btn btn-outline-success" style="width: 80%;"
                            (click)="abrirModalReportes()" pTooltip="Generar excel" tooltipPosition="left">
                            Reportes <i class="pi pi-file-excel"></i>
                          </button>
                        </div>

                        <div class="col-md-6  ml-5">
                          <button type="button" class="btn btn-outline-primary" style="width: 80%;"
                            (click)="validacionmen()" tooltipPosition="left">
                            Validación Mensual <i class="pi pi-check"></i>
                          </button>
                        </div>
                      </div>



                      <div class="row d-flex justify-content-end align-items-center  col-md-7">


                        <div class="col-md-3">
                          <p-columnFilter field="id_estatus" matchMode="equals" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                              <p-dropdown [ngModel]="value" [options]="lstunidadesEstatus" optionLabel="nombre"
                                optionValue="id" (onChange)="filter($event.value)" placeholder="Estatus de unidad"
                                [showClear]="true">
                              </p-dropdown>
                            </ng-template>
                          </p-columnFilter>
                        </div>

                        <div class="col-md-3">
                          <p-columnFilter field="id_ciudad" matchMode="equals" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                              <p-dropdown [ngModel]="value" [options]="lstCiudades" optionLabel="nombre"
                                optionValue="id" (onChange)="filter($event.value)" placeholder="Filtrar ciudad"
                                [showClear]="true">
                              </p-dropdown>
                            </ng-template>
                          </p-columnFilter>
                        </div>
                        <div class="col-md-3">
                          <p-columnFilter field="id_configuracionUnidad" matchMode="equals" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                              <p-dropdown [ngModel]="value" [options]="lstConfUnidad" optionLabel="nombre"
                                optionValue="clave_configuracionUnidad" (onChange)="filter($event.value)"
                                placeholder="Tipo de unidad" [showClear]="true">
                              </p-dropdown>
                            </ng-template>
                          </p-columnFilter>
                        </div>
                        <div class="col-md-3 d-flex justify-content-end">
                          <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" size="35" (input)="applyFilterGlobal($event, 'contains')"
                              placeholder="Buscar" class="form-control" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" pSortableColumn="num_economico">Número económico<p-sortIcon
                        field="num_economico"></p-sortIcon> </th>
                    <th class="text-center" pSortableColumn="desc_placaUnidad">Placa<p-sortIcon
                        field="desc_placaUnidad"></p-sortIcon></th>
                    <th class="text-center" pSortableColumn="vin">VIN<p-sortIcon field="vin"></p-sortIcon></th>
                    <th class="text-center">Descripción general</th>
                    <th class="text-center" pSortableColumn="id_unidad_transporte">Transporte<p-sortIcon
                        field="id_unidad_transporte"></p-sortIcon> </th>
                    <th class="text-center" pSortableColumn="clave_tipoUnidad">Tipo de configuracion<p-sortIcon
                        field="clave_tipoUnidad"></p-sortIcon> </th>
                    <th class="text-center" pSortableColumn="clave_tipoUnidad">Tipo de Unidad<p-sortIcon
                        field="clave_tipoUnidad"></p-sortIcon> </th>
                    <th class="text-center" pSortableColumn="id_ciudad">Ciudad de Operación<p-sortIcon
                        field="id_ciudad"></p-sortIcon> </th>
                    <th>Estatus/ Sub-Estatus</th>
                    <th style="width: 150px;"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data>
                  <tr>
                    <td class="text-center" style="color: #1e5a9c;"><b>{{data.num_economico}}</b> </td>
                    <td class="text-center">{{data.desc_placaUnidad}}</td>
                    <td class="text-center">{{data.vin}}</td>
                    <td class="text-center">{{ data.desc_marca +' '+
                      data.desc_modelo + ' '+data.desc_color}}<p style="display: inline;">-{{data.num_anio}}</p>
                    </td>
                    <td class="text-center">{{data.desc_transporte}}</td>
                    <td class="text-center">{{data.desc_unidad_transporte}}</td>

                    <td class="text-center">{{data.desc_tipo_unidad}}</td>
                    <td class="text-center">{{data.desc_ciudad}}</td>
                    <td class="texto_responsable ">{{data.desc_estatus +' -'+ data.desc_sub_estatus}}</td>
                    <td style="width: 150px;">
                      <div class="d-flex justify-content-around">

                        <button pButton pRipple class="custom-buttoncargar" icon="pi pi-plus"
                          (click)="mostrarModalAgregarFolio(data);" pTooltip="Agregar folio"></button>
                        <button pButton pRipple class="custom-his" icon="pi pi-book"
                          (click)="gestionarFoliosDeRuta(data);" pTooltip="Historico"></button>

                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="row mt-3 d-flex justify-content-center">
              <!-- <div class="col-6">
                                <div class="mt-3">
                                    <p-colorPicker [(ngModel)]="color_rojo_especial" [disabled]="true"></p-colorPicker>
                                    <p class="text-left informativo" style="display: inline;">Rutas Especiales</p>
                                </div>
                                <div class="mt-3 mb-2">
                                    <p-colorPicker [(ngModel)]="color_gris_deshabilitadas"
                                        [disabled]="true"></p-colorPicker>
                                    <p class="text-left informativo" style="display: inline;">Ruta INACTIVA</p>
                                </div>
                            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>


    <!--AGREGAR DE FOLIOS DE PROYECTO POR RUTA-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dsbAgregarfolio"
      [style]="{width: '50vw','height':'550px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarModalAgregarFolio()">
      <ng-template pTemplate="header">
        Agregar Folio de Proyecto Mensual-Unidades
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <div class="row">
            <div class="col-md-12">
              <p class="d-flex align-items-center texto-valido">Número económico: &nbsp;{{desc_num}}</p>
            </div>
          </div>
          <form [formGroup]="foliosForm" (ngSubmit)="guardarInformacionFolio()">
            <span class="title-light mt-3">Información del Folio<i class="pi pi-cog" style="size: 2rem;"></i>
            </span>
            <div class="row mt-3">
              <div class="form-group col-md-6">
                <div class="row g-0">
                  <div class="col-sm-6 d-flex align-items-center">
                    <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size: 1.5rem"></i>
                      <p class="ml-1 color-icon d-inline">mes</p>
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <p-dropdown [options]="meses" optionLabel="nombre" placeholder="Mes*" autoWidth="false"
                      [style]="{'width':'100%'}" [showClear]="true" formControlName="num_mes"
                      tooltip="Es el Mes a registrar" tooltipPosition="left" optionValue="num_mes"></p-dropdown>
                    <hr class="my-0">
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <div class="row g-0">
                  <div class="col-sm-6 d-flex align-items-center">
                    <span class="input-group-addon"> <i class="pi pi-calendar color-icon" style="font-size: 1.5rem"></i>
                      <p class="ml-1 color-icon d-inline">Año</p>
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <p-calendar formControlName="num_anio" [showIcon]="true" view="year" dateFormat="yy"
                      inputId="yearpicker" [style]="{'width':'100%'}" placeholder="Seleccione un año*"
                      pTooltip="Seleccione o ingrese el año"></p-calendar>
                    <hr class="my-0">


                    <!-- <p-calendar [(ngModel)]="anio_filtro" view="year" [showIcon]="true" dateFormat="yy"
                    inputId="yearpicker" [style]="{'width':'100%','height':'80%'}"
                    pTooltip="Año de Semanas a Consultar" tooltipPosition="top"></p-calendar> -->


                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="form-group col-md-6">
                <div class="row g-0">
                  <div class="col-sm-6 d-flex align-items-center">
                    <span class="input-group-addon"> <i class="pi pi-book color-icon" style="font-size: 1.5rem"></i>
                      <p class="ml-1 color-icon d-inline">Folio del Proyecto</p>
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <input type="string" class="form-control sinborde" pTooltip="Folio" tooltipPosition="top"
                      formControlName="num_folio_proyecto" style="text-transform:uppercase;">
                    <hr class="my-0">
                  </div>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center mt-5">
              <div class="col-4">
                <p *ngIf="!foliosForm.valid" class="texto-invalido text-right">
                  <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario
                    Proporcionar La Informaci&oacute;n Obligatoria(*)</i>
                </p>
                <p *ngIf="foliosForm.valid" class="texto-valido text-right">
                  <i class="pi pi-check-circle" style="font-size: 1rem;">Informaci&oacute;n
                    Completa</i>
                </p>
              </div>
              <div class="col-2">
                <button type="submit" class="btn btn-success w-100"
                  [disabled]="!foliosForm.valid || habilitar_edicion_costo">
                  <span class="text-white">Aceptar</span>
                  <i class="pi pi-save text-white"></i>
                </button>
              </div>
            </div>
          </form>
          <div class="row d-flex justify-content-end mb-5">
            <div class="col-md-3">

              <button pButton pRipple class="custom-warn" icon="pi pi-eraser" (click)="habilitarEdicionFolios();"
                pTooltip="Habilitar campos para actualizar Información">Habilitar Edición</button>


            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarModalAgregarFolio();"></button>
      </ng-template>
    </p-dialog>




    <!--HISTORICO DE FOLIOS DE PROYECTO POR RUTA-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dsbgestionfolios"
      [style]="{width: '60vw','height':'630px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"

      (onHide)="cancelarModalHistoricoFolios()">
      <ng-template pTemplate="header">
        Gestión de Folios de Proyecto Mensual- Unidades
      </ng-template>
      <ng-template pTemplate="content">
        <!--TABLA HISTORICO DE FOLIOS-->
        <div class="row mt-3">
          <div class="col-md-12">
            <p-table dataKey="id" [value]="lstfolios" [tableStyle]="{ 'min-width': '50rem' }"
              styleClass="p-datatable-sm" [loading]="loadinglst" [scrollable]="true" scrollHeight="380px">
              <ng-template pTemplate="caption">
                <div class="row">
                  <div class="col-md-12">
                    <p class="d-flex align-items-center texto-valido">
                      Número económico:&nbsp;{{desc_num}}
                    </p>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th>Mes</th>
                  <th>Año</th>
                  <th>Folio</th>
                  <th class="text-center">Editar</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-data let-index="rowIndex">
                <tr [pEditableRow]="data">
                  <td class="color-numeconomico">{{data.desc_mes }}</td>
                  <td class="color-sombreado">{{ data.num_anio }}</td>
                  <td class="color-resaltado">
                    {{ data.num_folio_proyecto | uppercase }}
                  <td>
                    <div class="flex align-items-center justify-content-center gap-2">
                      <button pButton pRipple class="custom-runded " icon="pi pi pi-pencil"
                        (click)="mostrarModalEditarFolio(data);" pTooltip="Editar Folio Mensual"></button>


                      <!-- <button  pButton pRipple type="button" pInitEditableRow
                        icon="pi pi-pencil" (click)="mostrarModalEditarFolio(data)" class="p-button-rounded"
                        pTooltip="Admin-Editar Folio Mensual"></button> -->
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarModalHistoricoFolios();"></button>
      </ng-template>
    </p-dialog>




    <!--EDITAR DE FOLIOS DE PROYECTO POR RUTA - ADMIN-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dsbAgregarHerramientasEditar"
      [style]="{width: '50vw','height':'550px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarModalEditarFolio()">
      <ng-template pTemplate="header">
        Editar Folio de Proyecto Mensual- Ruta
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <div class="row">
            <div class="col-md-12">
              <p class="d-flex align-items-center texto-valido">Número económico&nbsp;{{desc_num}}</p>
            </div>
          </div>
          <form [formGroup]="foliosForm" (ngSubmit)="guardarInformacionFolioEditar()">
            <span class="title-light mt-3">Información del Folio<i class="pi pi-cog" style="size: 2rem;"></i>
            </span>
            <div class="row mt-3">
              <div class="form-group col-md-6">
                <div class="row g-0">
                  <div class="col-sm-6 d-flex align-items-center">
                    <span class="input-group-addon"> <i class="pi pi-bars color-icon" style="font-size: 1.5rem"></i>
                      <p class="ml-1 color-icon d-inline">Mes</p>
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <p-dropdown [options]="meses" optionLabel="nombre" placeholder="Mes*" autoWidth="false"
                      [style]="{'width':'100%'}" [showClear]="true" formControlName="num_mes"
                      tooltip="Es el Mes a registrar" tooltipPosition="left" optionValue="num_mes"></p-dropdown>
                    <hr class="my-0">
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <div class="row g-0">
                  <div class="col-sm-6 d-flex align-items-center">
                    <span class="input-group-addon"> <i class="pi pi-calendar color-icon" style="font-size: 1.5rem"></i>
                      <p class="ml-1 color-icon d-inline">Año</p>
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <input type="number" min="0" class="form-control sinborde" formControlName="num_anio"
                      style="text-transform:uppercase; font-weight: bold; color: brown;" pTooltip="Año">
                    <hr class="my-0">
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="form-group col-md-6">
                <div class="row g-0">
                  <div class="col-sm-6 d-flex align-items-center">
                    <span class="input-group-addon"> <i class="pi pi-book color-icon" style="font-size: 1.5rem"></i>
                      <p class="ml-1 color-icon d-inline">Folio del Proyecto</p>
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <input type="string" class="form-control sinborde" pTooltip="Folio" tooltipPosition="top"
                      formControlName="num_folio_proyecto" style="text-transform:uppercase;">
                    <hr class="my-0">
                  </div>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center mt-5">
              <div class="col-4">
                <p *ngIf="!foliosForm.valid" class="texto-invalido text-right">
                  <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario
                    Proporcionar La Informaci&oacute;n Obligatoria(*)</i>
                </p>
                <p *ngIf="foliosForm.valid" class="texto-valido text-right">
                  <i class="pi pi-check-circle" style="font-size: 1rem;">Informaci&oacute;n
                    Completa</i>
                </p>
              </div>
              <div class="col-2">
                <button type="submit" class="btn btn-success w-100"
                  [disabled]="!foliosForm.valid || habilitar_edicion_costo">
                  <span class="text-white">Aceptar</span>
                  <i class="pi pi-save text-white"></i>
                </button>
              </div>
            </div>
          </form>
          <div class="row d-flex justify-content-end mb-5">
            <div class="col-md-3">

              <button pButton pRipple class="custom-warn" icon="pi pi-eraser"
                (click)="habilitarEdicionFolios_historico();"
                pTooltip="Habilitar campos para actualizar Información">Habilitar Edición</button>

            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarModalEditarFolio();"></button>
      </ng-template>
    </p-dialog>



    <!--REPORTES-->
    <div>
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
      [style]="{width: '65vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarModalReportes()">
      <ng-template pTemplate="header">
        Reportes
      </ng-template>
      <ng-template pTemplate="content">

        <div class="mt-5">
          <div class="row">
            <div class="col-md-4 mt-3">
              <p class="texto-pesado">Folio Unidades(Mes y Año)</p>
            </div>
            <div class="col-md-3">
              <p-dropdown [options]="meses" optionLabel="nombre" placeholder="Mes*" autoWidth="false"
                [style]="{'width':'100%'}" [showClear]="true" [(ngModel)]="selectedMesReporte1"
                tooltip="Es el Mes a consultar" tooltipPosition="left"></p-dropdown>

            </div>
            <div class="col-md-2">
              <p-calendar [(ngModel)]="anio_valid" [showIcon]="true" view="year" dateFormat="yy"
                [style]="{'width':'100%'}" placeholder="Seleccione un año*"></p-calendar>

            </div>
            <div class="col-md-2 ml-3 mt-1">
              <button type="button" class="btn btn-outline-success" style="width: 100%;" (click)="generarReporte1()">
                Exportar <i class="pi pi-file-export"></i>
              </button>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-2 text-center">
              <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte"></i>
            </div>
          </div>
          <hr class="style-two">
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarModalReportes();"></button>
      </ng-template>
    </p-dialog>


    <!-- -----------------validacion  mensual------------------- -->

    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsvalidacion"
  [style]="{width: '65vw','height':'800px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
  (onHide)="cancelarvalidacionmen()">
  <ng-template pTemplate="header">
    Comprobación de Ingreso de Folio Mensual
  </ng-template>

  <ng-template pTemplate="content">
    <!-- <div class="row d-flex justify-content-center">
      <div class="col-12 text-center">
        <p class="texto-pesado">Folio Unidades</p>
      </div>
    </div> -->
    <div class="row mt-1 d-flex justify-content-start">
      <div class="col-sm-6">
        <span class="title-light">Filtre para obtener las unidades sin folios asignados durante el mes
          <i class="pi pi-cog" style="size: 2rem;">
          </i>
        </span>
      </div>
    </div>

    <div class="mt-4 d-flex justify-content-around">
      <div class="col-md-3">
        <p-dropdown [options]="meses" optionLabel="nombre" placeholder="Mes*" autoWidth="false"
          [style]="{'width':'100%'}" [(ngModel)]="selectedMesReporte1" tooltip="Es el Mes a consultar"
          tooltipPosition="left"></p-dropdown>
      </div>

      <div class="col-md-3">
        <p-calendar [(ngModel)]="anioReporte1" [showIcon]="true" view="year" dateFormat="yy" [style]="{'width':'100%'}"
          placeholder="Seleccione un año*"></p-calendar>
      </div>

      <div class="col-md-2 ml-3 mt-1">
        <button type="button" class="btn btn-outline-primary" style="width: 100%;" (click)="getvalidacionfolios()">
          Filtrar <i class="pi pi-file-export"></i>
        </button>
      </div>

      <!-- Cargando spinner -->
      <div class="row d-flex justify-content-center">
        <div class="col-2 text-center">
          <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte"></i>
        </div>
      </div>

    </div>
    <hr class="style-two">

  <!-- Tabla con los resultados -->
   <div class="mt-5 " *ngIf="deshabilitartabla">
    <div class="row d-flex justify-content-center">
      <div class="col-md-3"></div>
      <div class="col-6 text-center">
        <p class="texto-pesado">Listado de unidades sin folio</p>
      </div>

    <div class="col-md-3 d-flex justify-content-end">
      <span class="p-input-icon-left p-ml-auto">
        <i class="pi pi-search"></i>
        <input pInputText type="text" size="35" (input)="applyFilterGlobal2($event, 'contains')"
          placeholder="Buscar" tooltipPosition="top" class="form-control" pTooltip=" Filtre por numero económico y ciudad" />
      </span>
    </div></div>
    <p-table #dt2 [value]="lstUnidadessinfolio" [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-sm"
    [scrollable]="true" scrollHeight="320px" responsiveLayout="scroll" [paginator]="true" [rows]="50"
    [rowsPerPageOptions]="[25,50,75,100,150]" [showCurrentPageReport]="true" [resizableColumns]="false"
    [globalFilterFields]="['num_economico','desc_ciudad',
              ]"
    currentPageReportTemplate="{first} a {last} de {totalRecords} Registros" [loading]="loading">


    <ng-template pTemplate="header">
      <tr>
        <th class="text-center" pSortableColumn="num_economico">Número económico<p-sortIcon field="num_economico"></p-sortIcon></th>
        <th class="text-center" pSortableColumn="desc_placaUnidad">Placa<p-sortIcon field="desc_placaUnidad"></p-sortIcon></th>
        <th class="text-center" pSortableColumn="desc_trasporte">Transporte<p-sortIcon field="desc_trasporte"></p-sortIcon></th>
        <th class="text-center" pSortableColumn="desc_configuracion">Tipo de configuración<p-sortIcon field="desc_configuracion"></p-sortIcon></th>
        <th class="text-center" pSortableColumn="desc_unidad">Tipo de Unidad<p-sortIcon field="desc_unidad"></p-sortIcon></th>
        <th class="text-center" pSortableColumn="desc_ciudad">Ciudad de Operación<p-sortIcon field="desc_ciudad"></p-sortIcon></th>
        <th pSortableColumn="desc_estatus">Estatus/Sub-Estatus<p-sortIcon field="desc_estatus"></p-sortIcon></th>

      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-fol>
      <tr>
        <td class="text-center" style="color: #1e5a9c;"><b>{{fol.num_economico}}</b></td>
        <td class="text-center">{{fol.desc_placaUnidad}}</td>


        <td class="text-center">{{fol.desc_trasporte}}</td>
        <td class="text-center">{{fol.desc_configuracion}}</td>
        <td class="text-center">{{fol.desc_unidad}}</td>
        <td class="text-center">{{fol.desc_ciudad}}</td>
        <td class="texto_responsable">{{fol.desc_estatus}}</td>

      </tr>
    </ng-template>
  </p-table>
</div>
</ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
      (click)="cancelarvalidacionmen();"></button>
  </ng-template>
</p-dialog>

</div>
  </div>
</div>
