import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { IDefaultRmOp, IDefaultRmOpCC } from '../models/rendimientoOperadores';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class RendimientoOperadoresService {
  private apiUrl: string = '';
  constructor(private http: HttpClient) {
    this.apiUrl = environment.sUrlGd + 'performance/operator';
  }

  getCiudadesYConfiguracionesUnidades(): Observable<IDefaultRmOpCC> {
    return this.http
      .get<IDefaultRmOpCC>(this.apiUrl + '/cities-conf')
      .pipe(retry(1), catchError(this.handleError));
  }

  getResumenRendimientoOperaciones(month: String, year: String, city: String, conf: String): Observable<IDefaultRmOp> {
    return this.http
      .get<IDefaultRmOp>(this.apiUrl + '/general?month=' + month + '&year=' + year + '&city=' + city + '&unit=' + conf)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getReporteFechasSegHerramientaHeaders(type: String): Observable<any> {
    const headers = { 'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
    return this.http.get<Blob>(this.apiUrl + '/xls?type=' + type, { observe: 'response', responseType: 'blob' as 'json' });
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
