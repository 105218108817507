import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MessageService, ConfirmationService, PrimeNGConfig, ConfirmEventType } from 'primeng/api';
import { Table } from 'primeng/table';
import { IChoferDisponible } from '../models/choferdisponible';
import { IColaboradorConfirmacion } from '../models/colaborador_confirmacion';
import { IColumnas } from '../models/columnas';
import ICredencialUsuario from '../models/credencial';
import { IEstatus } from '../models/estatus';
import { IChoferForm } from '../models/formchofer';
import { IModelDiaReal } from '../models/modeldiareal';
import { IMovDiarioUnidadCompleto } from '../models/movdiariocompleto';
import { IMovDiarioUnidad } from '../models/movdiariounidad';
import { IOperacionDia } from '../models/operaciondia';
import { IPuestochofer } from '../models/puestochofer';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { IUnidadDisponible } from '../models/unidaddisponible';
import { AdminpaounidadesService } from '../services/adminpaounidades.service';
import { AuthService } from '../services/auth.service';
import { ChoferService } from '../services/chofer.service';
import { ProyeccionService } from '../services/proyeccion.service';
import { UnidadtransporteService } from '../services/unidadtransporte.service';
import { Iaprovechamiento } from '../models/aprovechamiento';




//import { Iaprovechamiento } from '../models/aprovechamiento';
@Component({
  selector: 'app-real',
  templateUrl: './real.component.html',
  styleUrls: ['./real.component.scss']
})

export class RealComponent implements OnInit {
  @ViewChild('dt1') dt!: Table;
  @ViewChild('dt2') dt2!: Table;
  lstOperacionDiariaReal: IOperacionDia[] = [];
  lstOperacionDiariaUnidades: IMovDiarioUnidad[] = [];
  cols: IColumnas[] = [];

  clv_operacion: number = 0;
  dia: any = new Date("2023-03-02").toISOString().substring(0, 10);
  fecha_filtrada: any = new Date("2023-03-02").toISOString().substring(0, 10);
  fecha_dia: any = new Date("2023-03-02").toISOString().substring(0, 10);
  fecha_dia_final: any = null;
  fecha_dia_final_editar: any = null;
  checked: boolean = true;
  checked2: boolean = false;
  lstMovUnidades: IMovDiarioUnidad[] = [];
  dspEditar: boolean = false;
  nombre_ruta_Editable: string = '';
  fecha_dia_editable: string = '';
  hora: any;
  DilogEditar: boolean = false;
  id_pao_unidades: number = 0;
  permite_editar: boolean = false;
  permite_eliminar: boolean = false;
  //Unidades agregadadas al dia
  model: IModelDiaReal = {
    id_registro: 0,
    id_tipo_transporte: 0,
    id_ciudad: 0,
    id_ruta: 0,
    fecha: '',
    clv_operacion: 0,
  };
  /*reactiveForm!:FormGroup;*/
  reactiveForm: FormGroup = new FormGroup({
    fecha: new FormControl(this.fecha_dia, [Validators.required]),
    id_ruta_travel: new FormControl('', [Validators.required]),
    unidades: new FormControl(0, [Validators.required]),
    choferes: new FormControl(0, [Validators.required]),
    clv_retardo: new FormControl<number | null>(null),
    clv_retardo1: new FormControl(0, []),
    clv_retardo2: new FormControl(0, []),
    id_puesto1: new FormControl(0, []),
    id_puesto2: new FormControl(0, []),
    cod_adicional1: new FormControl(0, []),
    cod_adicional2: new FormControl(0, []),
    clv_confirma_combustible: new FormControl<number | null>(null),
    clv_confirma_casetas: new FormControl<number | null>(null),
    id_tipo_aprovechamiento: new FormControl(0, [Validators.required]),
  });


  lstpuestos: IPuestochofer[] = [{
    id: 1,
    nombre: 'DRIVER',
    descripcion_Puesto: ''
  },
  {
    id: 2,
    nombre: 'AUXILIAR',
    descripcion_Puesto: ''
  }];
  reactiveForm2!: FormGroup;
  //Consulta de Unidades
  dspEditarConsulta: boolean = false;

  //Unidades Disponibles y Chofer
  lstUnidadesDisponibles: IUnidadDisponible[] = [];
  lstChoferesDisponibles: IChoferDisponible[] = [];

  lstaprovechamiento: Iaprovechamiento[] = [];

  loading: boolean = false;
  loadingEditar: boolean = false;
  selectedUnidad: IUnidadDisponible = {
    num_economico: '',
    clave_ciudadDestino: 0,
    id_unidad_transporte: 0
  };

  selectedChofer: IChoferDisponible = {
    cod_chofer: 0,
    nombre_completo: '',
    id_ciudad_hm: 0,
    id_puesto_chofer: 0,
    clave_tipoTransporte: 0
  };
  dspConfirmarAsignacion: boolean = false;
  leyenda_IdRutaTravelId: string = '';
  leyenda_unidad: string = '';
  leyenda_chofer: string = '';
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };
  color_rojo_especial: string = '#FF0000';

  //Boton y Eventos Especiales
  desactivarBotonGuardar: boolean = false;
  descripcion_ruta: string = '';
  reactiveform_paso: IChoferForm = {
    fecha: '',
    choferes: 0,
    unidades: 0
  };

  //Habilitar botones
  dsbUnidad: boolean = true;
  dsbChofer: boolean = true;
  dsbRutas: boolean = true;
  //Registro
  user: ICredencialUsuario = {};
  lstEstatusEspecial: IEstatus[] = [{
    id: 1,
    nombre: 'ESPECIAL'
  },
  {
    id: 0,
    nombre: 'NORMAL'
  }];
  nombre_proyecto_general: string = '';
  cod_usuario_general: string = '';
  //Permiso para eliminar informacion
  clv_pa: number = 0;
  lstConfirmacionColaboradores: IColaboradorConfirmacion[] = [];
  clv_especial: number = 0;
  constructor(private proyeccionService: ProyeccionService,
    private unidadService: UnidadtransporteService,
    private messageService: MessageService,
    private choferService: ChoferService,
    private confirmationService: ConfirmationService, private primengConfig: PrimeNGConfig, private fb: FormBuilder,
    private auth: AuthService,
    private adminpaounidadesService: AdminpaounidadesService) {
    this.cargarInformacionUsuario();
    this.reactiveForm.controls['cod_adicional1'].valueChanges.subscribe(data => {
      this.filtrarListadoColaboradores(data);
    });
    this.reactiveForm.controls['cod_adicional2'].valueChanges.subscribe(data => {
      this.filtrarListadoColaboradores(data);
    });
    this.reactiveForm.controls['choferes'].valueChanges.subscribe(data => {
      this.filtrarListadoColaboradores(data);
    });
    //
  }
  ngOnInit(): void {
    this.llenarColumnas();
    this.inicializarFechas();
    this.llenarInformacionAutomaticaDia();
    //this.crearFormulario();
    this.crearFormularioFB();
    this.getcataprovechamiento();
  }

  filtrarListadoColaboradores(cod_chofer: number) {
    let chofer = this.reactiveForm.get('choferes')?.value == null ? 0 : this.reactiveForm.get('choferes')?.value;
    let chofer1 = this.reactiveForm.get('cod_adicional1')?.value == null ? 0 : this.reactiveForm.get('cod_adicional1')?.value;
    let chofer2 = this.reactiveForm.get('cod_adicional2')?.value == null ? 0 : this.reactiveForm.get('cod_adicional2')?.value;

    //se agrego la variable id_pao_unidades
    if (this.id_pao_unidades != 0) {
      //---
      if (this.lstChoferesDisponibles.length > 0) {
        this.lstChoferesDisponibles.map((valor) => {
          if (valor.cod_chofer == cod_chofer) {
            valor.disabled = true;
          }
          else if (valor.cod_chofer != chofer && valor.cod_chofer != chofer1 && valor.cod_chofer != chofer2) {
            valor.disabled = false;
          }
        });
      }

    }
    else {
      if (this.lstChoferesDisponibles.length > 0) {
        this.lstChoferesDisponibles.map((valor) => {
          if (valor.cod_chofer == cod_chofer) {
            valor.disabled = true;
          }
          else if (valor.cod_chofer != chofer && valor.cod_chofer != chofer1 && valor.cod_chofer != chofer2) {
            valor.disabled = false;
          }
        });
      }

    };
  }



  getcataprovechamiento() {
    this.loading = true;
    this.unidadService.getcataprovechamiento().subscribe((resp) => {
      this.lstaprovechamiento = resp;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }, (error) => {
      this.loading = false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error al obtener listado</b>. de aprovechamiento.');
    });
  }




  eliminarMovimiento(data: IMovDiarioUnidad) {
    let data_enviar: IMovDiarioUnidadCompleto = {
      id: data.idx,
      id_pao: data.idx_pao_diario,
      id_ruta: data.id_ruta,
      fecha: data.fecha,
      id_unidad: data.id_unidad_transporte,
      id_chofer: data.cod_chofer,
      cod_usuario: this.user.cod!,
      clv_confirm: 1
    };
    this.adminpaounidadesService.eliminarMovimiento(data_enviar).subscribe((resp) => {
      this.resultado = resp;
      //if para evitar que las ruts con recursoos ya asigandos sean eliminados itzael
      if (this.resultado.clv_estatus == -1) {
        this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Atención en <Eliminar Servicio>', detail: 'NO se pueden eliminar rutas con recursos ya asignados o Facturas activas.' });
      }
      else {
        this.messageService.add({ key: 'msj', severity: 'success', summary: 'Actualización', detail: 'La informacion se almaceno de forma correcta' });
      }
      this.cancelarMostrarConfirmacion();
      this.cancelarAgregarUnidadyChofer();
      this.cancelarMostrarConsulta();
      this.llenarInformacionAutomaticaDiaAlGuardar(data_enviar.fecha.substring(0, 10));
    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Eliminar Servicio>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  confirmarDesactivar(data: IMovDiarioUnidad) {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b>Eliminar</b> el registro de Ruta <b>' + data.nombre_ruta + '</b> del Dia (' + data.fecha.substring(0, 10) + ') ?',
      header: 'Confirmación de Eliminar',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.eliminarMovimiento(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Rechazado', detail: 'Se Rechazo la operación' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se Cancelado la operación' });
            break;
        }
      }
    });
  }

  confirmarCancelarRuta(data: IMovDiarioUnidad) {
    let texto: string = data.clv_cancelado == 1 ? 'HABILITAR' : 'CANCELAR';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b>' + texto + '</b> el registro de Ruta <b>' + data.nombre_ruta + '</b> del Dia (' + data.fecha.substring(0, 10) + ') con la Unidad <b> ' +
        data.num_economico + '</b> ?',
      header: 'Confirmación de ' + texto + ' Ruta',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.cancelarMovimiento(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Rechazado', detail: 'Se Rechazo la operación' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se Cancelado la operación' });
            break;
        }
      }
    });
  }


  //editar ruta itzael
  confirmarEditarRuta(data: IMovDiarioUnidad) {

    this.DilogEditar = true;
    //editar
    let texto: string = data.clv_cancelado == 1 ? 'HABILITAR' : 'CANCELAR';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b>' + texto + '</b> el registro de Ruta <b>' + data.nombre_ruta + '</b> del Dia (' + data.fecha.substring(0, 10) + ') con la Unidad <b> ' +
        data.num_economico + '</b> ?',
      header: 'Confirmación de ' + texto + ' Ruta',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.editarUnidadxDia(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Rechazado', detail: 'Se Rechazo la operación' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se Cancelado la operación' });
            break;
        }
      }
    });
  }

  cancelarMovimiento(data: IMovDiarioUnidad) {
    this.adminpaounidadesService.cancelarMovimiento(data.idx).subscribe((resp) => {
      this.resultado = resp;
      this.messageService.add({ key: 'msj', severity: 'success', summary: 'Actualización', detail: 'La informacion se almaceno de forma correcta' });
      this.cancelarMostrarConfirmacion();
      this.cancelarAgregarUnidadyChofer();
      this.cancelarMostrarConsulta();
      this.llenarInformacionAutomaticaDiaAlGuardar(data.fecha.substring(0, 10));
    },
      (error) => {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cancelar Servicio>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  crearFormularioFB() {
    this.reactiveForm2 = this.fb.group({
      fecha: new FormControl(this.fecha_dia),
      id_ruta_travel: new FormControl('', []),
      unidades: new FormControl(0, [
        Validators.required
      ]),
      choferes: new FormControl(0, [
        Validators.required
      ])
    });
  }

  asignarChofer(data: any) {
    let cod_chofer = data;
    this.lstChoferesDisponibles.map((valor) => {
      if (valor.cod_chofer = cod_chofer) {
        this.selectedChofer = valor;
      }
    });
  }

  asignarUnidad(data: any) {
    let id_unidad_transporte = data;
    this.lstUnidadesDisponibles.map((unidad) => {
      if (unidad.id_unidad_transporte = id_unidad_transporte) {
        this.selectedUnidad = unidad;
      }
    });
  }

  crearFormulario() {
    this.reactiveForm = new FormGroup({
      fecha: new FormControl(this.fecha_dia, [
        Validators.required
      ]),
      id_ruta_travel: new FormControl('', []),
      unidades: new FormControl(0, [
        Validators.required
      ]),
      choferes: new FormControl(0, [
        Validators.required
      ])
    });
  }

  mostrarFormularioReactivo() {
  }




  asignarValorChofer() {
    let cod_chofer = this.reactiveForm.get('choferes')?.value;
    this.selectedChofer = cod_chofer;
    /*this.lstChoferesDisponibles.map((valor)=> {
      if(valor.cod_chofer=cod_chofer){
        this.selectedChofer=valor;
      }
    });*/
  }
  //itzael id_ruta_travel
  get id_ruta_travel() {
    return this.reactiveForm.get('id_ruta_travel')!;
  }

  get unidades() {
    return this.reactiveForm.get('unidades')!;
  }

  get choferes() {
    return this.reactiveForm.get('choferes')!;
  }

  get ruta() {
    return this.reactiveForm.get('ruta')!;
  }


  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let restarTresDias = new Date(dateFormat);

    this.fecha_dia_final = dateFormat;
    this.fecha_dia_final_editar = restarTresDias.setDate(restarTresDias.getDate() - 3);
    this.dia = dateFormat;
    this.fecha_filtrada = dateFormat;
    this.fecha_dia = dateFormat;



  }


  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  /*Filtro General para la informacion de tabla de consulta de servicios*/
  applyFilterGlobal2($event: any, stringVal: any) {
    this.dt2.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }


  padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }



  llenarColumnas() {
    this.cols = [{ field: 'nombre_ciudad', header: 'Ciudad' },
    { field: 'nombre_cliente', header: 'Cliente' },
    { field: 'nombre_proyecto', header: 'Proyecto' },
    { field: 'nombre_ruta', header: 'Ruta' },
    { field: 'nombre_unidad', header: 'Unidad' },
    { field: '', header: '' },
    { field: '', header: '' }];
  }



  FiltrarFechaDiariaRuta() {
    this.loading = true;
    this.dia = this.fecha_filtrada;
    this.fecha_dia = this.fecha_filtrada;
    this.clv_operacion = 0;
    //let cod_usuario
    this.proyeccionService.getListadoOpDia(1, this.fecha_filtrada, this.user.cod).subscribe((resp) => {
      this.lstOperacionDiariaReal = [];
      setTimeout(() => {
        this.lstOperacionDiariaReal = resp;

        if (this.lstOperacionDiariaReal.length == 0) {
          this.loading = false;
          this.mostrarDialogoInformativoSinInformacion('<b>No Existe</b> información de Rutas para la Fecha ' + this.fecha_filtrada + ' seleccionada');
        }
        else {
          this.clv_operacion = resp[0].clv_operacion;
          this.loading = false;
        }

      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>ERROR</b> en Cargar Movimientos del Dia(Operación Diaria)');
        this.loading = false;
      });
  }

  llenarInformacionAutomaticaDia() {
    this.loading = true;
    this.proyeccionService.getListadoOpDia(1, this.fecha_dia, this.user.cod).subscribe((resp) => {
      setTimeout(() => {
        this.loading = false;
        this.lstOperacionDiariaReal = resp;
        this.clv_pa = resp.length > 0 ? resp[0].clv_pa : 0;
        if (this.lstOperacionDiariaReal.length == 0) {
          this.loading = false;
          this.mostrarDialogoInformativoSinInformacion('<b>No Existe</b> información de Rutas para la Fecha seleccionada');
        }
        else {
          this.clv_operacion = resp[0].clv_operacion;
        }
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>ERROR</b> en Cargar Movimientos del Dia(Operación Diaria- ' + this.fecha_dia + ' )');
        this.loading = false;
      });
  }

  llenarInformacionAutomaticaDiaAlGuardar(fecha: any) {
    this.loading = true;
    this.proyeccionService.getListadoOpDia(1, fecha, this.user.cod).subscribe((resp) => {
      setTimeout(() => {
        this.loading = false;
        this.lstOperacionDiariaReal = resp;
        if (this.lstOperacionDiariaReal.length == 0) {
          this.loading = false;
          this.mostrarDialogoInformativoSinInformacion('<b>No Existe</b> información de Rutas para la Fecha seleccionada');
        }
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>ERROR</b> en Cargar Movimientos del Dia(Operación Diaria- ' + fecha + ' )');
        this.loading = false;
      });
  }

  mostrarDialogoFolio(mensaje_usuario: string) {

    let mensaje_completo = 'El <b>#Folio</b> asignado para el Servicio es: <b>' + mensaje_usuario + '</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Asignación de Folio',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'success', summary: 'Actualización', detail: 'La informacion se almaceno de forma correcta' });
      },
      key: "folio"
    });
  }

  mostrarDialogoFolioEditar(mensaje_usuario: string) {

    let mensaje_completo: string = '<b>' + mensaje_usuario + '</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Asignación de Folio',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'success', summary: 'Actualización', detail: 'La informacion se almaceno de forma correcta' });
      },
      key: "folio"
    });
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  MostrarInformacionUnidades(id: number, data: IOperacionDia) {
  }
tipo_aprovechamiento?:string =''

  cargarInformacionDeMovDiarioUnidades(idx_pao_diario: number, id_ruta: number, fecha: string, nom_proyecto: string) {
    this.nombre_proyecto_general = nom_proyecto;
    this.loadingEditar = true;
    this.proyeccionService.getListadoUnidadesDia(1, idx_pao_diario, id_ruta, fecha).subscribe((resp) => {
      this.lstOperacionDiariaUnidades = resp;

      this.lstOperacionDiariaUnidades.forEach((unidad) => {
        let aprovechamiento = this.lstaprovechamiento.find(x => x.id_tipo_aprovechamiento === unidad.id_tipo_aprovechamiento);
        if (aprovechamiento) {
          unidad.tipo_aprovechamiento = aprovechamiento.nombre;
        }
      });

      this.dspEditarConsulta = true;
      setTimeout(() => {
        this.loadingEditar = false;
      }, 800);
    },
    () => {
      this.loadingEditar = false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Unidades por el dia de Ruta.');
    });
  }



  mostrarDialogoInformativoAdicionales(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
      },
      reject: () => {
      },
      key: "sinInformacionDialog"
    });
  }


  confirmarInformacionAntesGuardar() {
    let cod_1 = this.reactiveForm.get('cod_adicional1')?.value;
    let cod_2 = this.reactiveForm.get('cod_adicional2')?.value;
    let id_pues1 = this.reactiveForm.get('id_puesto1')?.value == 0 ? null : this.reactiveForm.get('id_puesto1')?.value;
    let id_pues2 = this.reactiveForm.get('id_puesto2')?.value == 0 ? null : this.reactiveForm.get('id_puesto2')?.value;
    if (((cod_1 > 0 || cod_1 != null) && id_pues1 == null) || ((cod_2 > 0 || cod_2 != null) && id_pues2 == null)
      || ((id_pues1 > 0 || id_pues1 != null) && cod_1 == null)
      || ((id_pues2 > 0 || id_pues2 != null) && cod_2 == null)) {
      this.mostrarDialogoInformativoAdicionales('La informacion de <b>Colaboradores Adicionales</b> debe estar Completa(Puesto/Nombre).');
    } else {
      this.leyenda_IdRutaTravelId = this.reactiveForm.get('id_ruta_travel')?.value;
      this.leyenda_unidad = this.reactiveForm.get('unidades')?.value;
      this.leyenda_chofer = this.reactiveForm.get('choferes')?.value;
      this.lstConfirmacionColaboradores = [];

      //verificar aqui itzael
      let num_economico = this.obtenerNumeroEconomico(this.reactiveForm.get('unidades')?.value);
     // let aprovechamiento = this.reactiveForm.get('id_tipo_aprovechamiento')?.value;
      let aprovechamiento = this.lstaprovechamiento.find(x => x.id_tipo_aprovechamiento === this.reactiveForm.controls['id_tipo_aprovechamiento'].value);

      if (this.reactiveForm.get('choferes')?.value != null) {
        let nombre = this.obtenerNombreColaborador(this.reactiveForm.get('choferes')?.value);
        let puesto = 'DRIVER';
        this.lstConfirmacionColaboradores.push({
          num_economico: num_economico,
          desc_ruta: this.descripcion_ruta,
          nombre_completo: nombre,
          desc_puesto: puesto,
          aprovechamiento: aprovechamiento?.nombre,
          clv_retardo: (this.reactiveForm.get('clv_retardo')?.value == null || this.reactiveForm.get('clv_retardo')?.value == 0 ? 'NO' : 'SI')
        });
      }
      if (this.reactiveForm.get('cod_adicional1')?.value != null) {
        let nombre_adicional1 = this.obtenerNombreColaborador(this.reactiveForm.get('cod_adicional1')?.value);
        let puesto_adicional1 = this.obtenerPuesto(this.reactiveForm.get('id_puesto1')?.value);
        this.lstConfirmacionColaboradores.push({
          num_economico: num_economico,
          desc_ruta: this.descripcion_ruta,
          nombre_completo: nombre_adicional1,
          desc_puesto: puesto_adicional1,
          aprovechamiento: aprovechamiento?.nombre,
          clv_retardo: (this.reactiveForm.get('clv_retardo1')?.value == null || this.reactiveForm.get('clv_retardo1')?.value == 0 ? 'NO' : 'SI')
        });
      }
      if (this.reactiveForm.get('cod_adicional2')?.value != null) {
        let nombre_adicional2 = this.obtenerNombreColaborador(this.reactiveForm.get('cod_adicional2')?.value);
        let puesto_adicional2 = this.obtenerPuesto(this.reactiveForm.get('id_puesto2')?.value);
        this.lstConfirmacionColaboradores.push({
          num_economico: num_economico,
          desc_ruta: this.descripcion_ruta,
          nombre_completo: nombre_adicional2,
          desc_puesto: puesto_adicional2,
          aprovechamiento: aprovechamiento?.nombre,
          clv_retardo: (this.reactiveForm.get('clv_retardo2')?.value == null || this.reactiveForm.get('clv_retardo2')?.value == 0 ? 'NO' : 'SI')
        });
      }
      //cada vez que se abra el modal se vuelve a activar
      this.desactivarBotonGuardar = false;
      this.dspConfirmarAsignacion = true;
    }

  }

  obtenerNumeroEconomico(id_unidad_transporte: number): string {
    let nombre: string = '';
    this.lstUnidadesDisponibles.map((valor) => {
      if (valor.id_unidad_transporte == id_unidad_transporte) {
        nombre = valor.num_economico.toLocaleUpperCase();
      }
    });
    return nombre;
  }

  obtenerNombreColaborador(cod_chofer: number): string {
    let nombre: string = '';
    this.lstChoferesDisponibles.map((valor) => {
      if (valor.cod_chofer == cod_chofer) {
        nombre = valor.nombre_completo.toLocaleUpperCase();
      }
    });
    return nombre;
  }

  obtenerPuesto(id_puesto: number): string {
    let puesto: string = '';
    puesto = id_puesto == 1 ? 'DRIVER' : 'AUXILIAR';
    return puesto;
  }




  dbGuardarInformacionConfirmacion() {
  }



  cancelarMostrarConfirmacion() {
    this.dspConfirmarAsignacion = false;
    this.leyenda_chofer = '';
    this.leyenda_unidad = '';
    this.lstConfirmacionColaboradores = [];
  }

  //guardar rutas
  guardarUnidadaRutaxDia() {
    this.desactivarBotonGuardar = true;
    let fecha = this.reactiveForm.get('fecha')?.value;
    let id_ruta_travel: string = this.reactiveForm.get('id_ruta_travel')?.value == null
      || this.reactiveForm.get('id_ruta_travel')?.value == undefined
      || this.reactiveForm.get('id_ruta_travel')?.value == '' ? 'null' :
      this.reactiveForm.get('id_ruta_travel')?.value;
    let unidades: number = this.reactiveForm.get('unidades')?.value;
    let choferes: number = this.reactiveForm.get('choferes')?.value;

    //Formatear Fecha
    let format = fecha.split("-");
    let ultima_fecha = format[2] + '-' + format[1] + '-' + format[0];
    //usuario
    let clv_retardo_cast = this.reactiveForm.get('clv_retardo')?.value == null || this.reactiveForm.get('clv_retardo')?.value == false ? 0 : 1;
    let clv_retardo1_cast = this.reactiveForm.get('clv_retardo1')?.value == null || this.reactiveForm.get('clv_retardo1')?.value == false ? 0 : 1;
    let clv_retardo2_cast = this.reactiveForm.get('clv_retardo2')?.value == null || this.reactiveForm.get('clv_retardo2')?.value == false ? 0 : 1;


    //variables  de confirmaciond e cursos del check box itzael
    let clv_confirma_casetas = this.reactiveForm.get('clv_confirma_casetas')?.value == null || this.reactiveForm.get('clv_confirma_casetas')?.value == 0 ? 0 : 1;
    let clv_confirma_combustible = this.reactiveForm.get('clv_confirma_combustible')?.value == null || this.reactiveForm.get('clv_confirma_combustible')?.value == 0 ? 0 : 1;


    let id_puesto = 1;
    let id_puesto1 = this.reactiveForm.get('id_puesto1')?.value == null ? 0 : this.reactiveForm.get('id_puesto1')?.value;
    let id_puesto2 = this.reactiveForm.get('id_puesto2')?.value == null ? 0 : this.reactiveForm.get('id_puesto2')?.value;
    let cod_adicional1 = this.reactiveForm.get('cod_adicional1')?.value == null ? 0 : this.reactiveForm.get('cod_adicional1')?.value;
    let cod_adicional2 = this.reactiveForm.get('cod_adicional2')?.value == null ? 0 : this.reactiveForm.get('cod_adicional2')?.value;
    this.reactiveForm.get('id_puesto1')?.setValue(id_puesto1);
    this.reactiveForm.get('id_puesto2')?.setValue(id_puesto2);
    this.reactiveForm.get('cod_adicional1')?.setValue(cod_adicional1);
    this.reactiveForm.get('cod_adicional2')?.setValue(cod_adicional2);

    //Hora en Formato al Guardar
    var formattedDate = new Date().toLocaleString('es-MX', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false });
    var fecha_completa = fecha + 'T' + formattedDate;

    //if para la pantalla de editar utilizando el reactiveform
    if (this.id_pao_unidades != 0) {
      this.unidadService.postGuardarUnidadAlDia(
        3,
        this.id_pao_unidades,
        this.model.id_registro,
        this.model.id_ruta,
        fecha_completa,
        choferes,
        unidades,
        cod_adicional1,
        cod_adicional2,
        id_puesto,
        id_puesto1,
        id_puesto2,
        clv_retardo_cast,
        clv_retardo1_cast,
        clv_retardo2_cast,
        this.user.cod!,
        this.clv_especial,
        0,
        clv_confirma_combustible,
        clv_confirma_casetas,
        id_ruta_travel,
        this.reactiveForm.controls['id_tipo_aprovechamiento'].value


      ).subscribe((resp) => {
        this.resultado = resp;
        if (this.resultado.clv_estatus == -2) {
          this.mostrarDialogoInformativoAdicionales('<b>' + this.resultado.messageDetails + '</b>');
          this.cancelarMostrarConfirmacion();
        } else {
          this.mostrarDialogoFolioEditar(this.resultado.messageDetails);
          this.cancelarMostrarConfirmacion();
          this.cancelarAgregarUnidadyChofer();
          this.llenarInformacionAutomaticaDiaAlGuardar(fecha);
          this.DilogEditar = false;
          this.dspEditarConsulta = false;
        }
      },
        (error) => {
          this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Guardar Servicio al Dia>', detail: 'Contacte al Administrador del Sitio' });
          this.desactivarBotonGuardar = false;
          this.DilogEditar = false;
          this.dspEditarConsulta = false;
        });
    }
    else {
      this.unidadService.postGuardarUnidadAlDia(2, 0,
        this.model.id_registro,
        this.model.id_ruta,
        fecha_completa,
        choferes,
        unidades,
        cod_adicional1,
        cod_adicional2,
        id_puesto,
        id_puesto1,
        id_puesto2,
        clv_retardo_cast,
        clv_retardo1_cast,
        clv_retardo2_cast,
        this.user.cod!,
        this.clv_especial,
        0,
        clv_confirma_combustible,
        clv_confirma_casetas,
        id_ruta_travel,
        this.reactiveForm.controls['id_tipo_aprovechamiento'].value
      ).subscribe((resp) => {
        this.resultado = resp;
        if (this.resultado.clv_estatus == -2) {
          this.mostrarDialogoInformativoAdicionales(this.resultado.messageDetails);
          this.cancelarMostrarConfirmacion();
        } else {

          this.mostrarDialogoFolio(this.resultado.messageDetails);
          this.cancelarMostrarConfirmacion();
          this.cancelarAgregarUnidadyChofer();
          this.llenarInformacionAutomaticaDiaAlGuardar(fecha);
          this.DilogEditar = false;
          this.dspEditarConsulta = false;
        }
      },
        (error) => {
          this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Guardar Servicio al Dia>', detail: 'Contacte al Administrador del Sitio' });
          this.desactivarBotonGuardar = false;
          this.DilogEditar = false;
          this.dspEditarConsulta = false;
        });
    }

  }



  asignarValorUnidad() {
    let id_unidad_transporte = this.reactiveForm.get('unidades')?.value;
    this.selectedUnidad = id_unidad_transporte;

  }

  asignarValorFecha() {
    this.fecha_dia = this.fecha_filtrada;
  }

  revPermiteAgregar(): boolean {
    let diaActual: Date = new Date();
    let diasMas = new Date(diaActual.setDate(diaActual.getDate() + 1));

    let fecha_filtrada = new Date(this.fecha_filtrada);
    let dia_final = new Date(this.fecha_dia_final);

    if (fecha_filtrada < dia_final || fecha_filtrada > diasMas)
      return true;

    else
      return false;
  }

  agregarUnidadxDia(data: IOperacionDia, clv_especial: number, num_opcion_categoria: number) {
    this.id_pao_unidades = 0;
    this.reactiveForm.reset();
    this.descripcion_ruta = '';
    this.clv_especial = clv_especial;
    this.reactiveForm.controls['fecha'].setValue(data.fecha.substring(0, 10));
    this.model = {
      id_registro: data.idx_pao_diario,
      id_tipo_transporte: data.id_tipo_transporte,
      id_ciudad: data.id_ciudad,
      id_ruta: data.id_ruta,
      fecha: data.fecha,
      clv_operacion: data.clv_operacion,
    };
    this.descripcion_ruta = data.nombre_ruta;
    this.nombre_ruta_Editable = data.nombre_ruta;
    this.fecha_dia_editable = data.fecha.substring(0, 10);

    this.reactiveForm.controls['clv_confirma_combustible'].setValue(true);
    this.reactiveForm.controls['clv_confirma_casetas'].setValue(true);
    this.reactiveForm.get('unidades')!.disable();
    this.dsbUnidad = true;

    this.unidadService.getListadoUnidadesDisponiblesAlDia(num_opcion_categoria, data.id_tipo_transporte, data.id_ciudad, data.fecha).subscribe((resp) => {
      this.lstUnidadesDisponibles = resp;
      if (this.lstUnidadesDisponibles.length == 0) {
        this.mostrarDialogoInformativoSinInformacion('No hay Unidades Disponibles para la Ruta. Considere un servicio Especial.');
      } else {
        this.reactiveForm.get('unidades')!.enable();
        this.dsbUnidad = false;
      } setTimeout(() => {
      },
        900);
    },
      (error) => {
        this.dsbUnidad = true;
        this.reactiveForm.get('unidades')!.disable();
        this.dspEditar = false;
        this.messageService.add({ severity: 'error', summary: 'Error en <Unidad Disponible>', detail: 'Contacte al Administrador del Sitio' });
      });

    //Cargar Listado de Choferes Disponibles
    this.dsbChofer = true;
    this.reactiveForm.get('choferes')!.disable();
    this.choferService.getListadoChoferesDisponiblesAlDia(num_opcion_categoria, data.id_tipo_transporte, data.id_ciudad, data.fecha).subscribe((resp) => {
      this.lstChoferesDisponibles = resp;
      if (this.lstChoferesDisponibles.length == 0) {
        this.mostrarDialogoInformativoSinInformacion('No hay Colaboradores Disponibles para la Ruta. Considere una ruta Especial.');
      } else {
        this.reactiveForm.get('choferes')!.enable();
        this.dsbChofer = false;
      }
      setTimeout(() => {
      },
        900);
    },
      (error) => {
        this.dsbChofer = true;
        this.reactiveForm.get('choferes')!.disable();
        this.dspEditar = false;
        this.messageService.add({ severity: 'error', summary: 'Error en <Colaborador Disponible>', detail: 'Contacte al Administrador del Sitio' });
      });

    this.changeInput(data.clv_ruta_travel);
    this.dspEditar = true;
  }


  //itzael editar metodo
  changeInput(clv_ruta_travel: boolean) {
    if (this.clv_especial == 2) {
      this.reactiveForm.controls['id_ruta_travel'].clearValidators();
      this.reactiveForm.controls['id_ruta_travel'].setValidators([Validators.maxLength(25)]);
      this.reactiveForm.controls['id_ruta_travel'].updateValueAndValidity();
    }
    else {
      if (clv_ruta_travel) {
        this.reactiveForm.controls['id_ruta_travel'].clearValidators();
        this.reactiveForm.controls['id_ruta_travel'].setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(25)]);
        this.reactiveForm.controls['id_ruta_travel'].updateValueAndValidity();
      }
      else {
        this.reactiveForm.controls['id_ruta_travel'].clearValidators();
        this.reactiveForm.controls['id_ruta_travel'].setValidators([Validators.maxLength(25)]);
        this.reactiveForm.controls['id_ruta_travel'].updateValueAndValidity();
      }
    }
  }


  editarUnidadxDia(data: IMovDiarioUnidad) {
    //limpiar formulario
    this.reactiveForm.reset();
    this.lstConfirmacionColaboradores = [];
    this.descripcion_ruta = '';
    this.clv_especial = data.clv_especial;
    let clv_especial = 1;
    //agrege esta variable para poder hacer usu de reactiveform que ya exitiaa
    this.id_pao_unidades = data.idx;
    this.descripcion_ruta = data.nombre_ruta != undefined ? data.nombre_ruta : '';
    //Informacion a Mostrar en el encabezado
    this.nombre_ruta_Editable = data.nombre_ruta != undefined ? data.nombre_ruta : '';;
    this.fecha_dia_editable = data.fecha.substring(0, 10);


    //El servicio para mostrar las unidades disponibles
    this.dsbUnidad = true;
    this.changeInput(data.clv_ruta_travel);

    this.unidadService.getListadoUnidadesDisponiblesAlDia(4, this.model.id_tipo_transporte, this.model.id_ciudad, this.model.fecha).subscribe((resp) => {
      this.lstUnidadesDisponibles = resp;
      if (this.lstUnidadesDisponibles.length == 0) {
        this.mostrarDialogoInformativoSinInformacion('No hay Unidades Disponibles para la Ruta. Considere un servicio Especial.');
      } else {
        this.reactiveForm.get('unidades')!.enable();
        this.dsbUnidad = false;
      }
    },
      (error) => {
        this.dsbUnidad = true;
        this.reactiveForm.get('unidades')!.disable();
        this.DilogEditar = false;
        this.messageService.add({ severity: 'error', summary: 'Error en <Unidad Disponible>', detail: 'Contacte al Administrador del Sitio' });
      });


    //Cargar Listado de Choferes Disponibles
    this.dsbChofer = true;
    this.reactiveForm.get('choferes')!.disable();
    this.choferService.getListadoChoferesDisponiblesAlDia(2, this.model.id_tipo_transporte, this.model.id_ciudad, this.model.fecha).subscribe((resp) => {
      this.lstChoferesDisponibles = resp;
      if (this.lstChoferesDisponibles.length == 0) {
        this.mostrarDialogoInformativoSinInformacion('No hay Colaboradores Disponibles para la Ruta. Considere una ruta Especial.');
      } else {
        this.reactiveForm.get('choferes')!.enable();
        this.dsbChofer = false;
      }
    },
      (error) => {
        this.dsbChofer = true;
        this.reactiveForm.get('choferes')!.disable();
        this.DilogEditar = false;
        this.messageService.add({ severity: 'error', summary: 'Error en <Colaborador Disponible>', detail: 'Contacte al Administrador del Sitio' });
      });


    //se inserta la informaciopn ya cargada
    this.reactiveForm.controls['fecha'].setValue(data.fecha.substring(0, 10));
    this.reactiveForm.controls['clv_retardo'].setValue(data.clv_retardo);
    this.reactiveForm.controls['id_puesto1'].setValue(data.id_puesto1);
    this.reactiveForm.controls['id_puesto2'].setValue(data.id_puesto2);
    this.reactiveForm.controls['unidades'].setValue(data.id_unidad_transporte);
    this.reactiveForm.controls['cod_adicional1'].setValue(data.cod_adicional1);
    this.reactiveForm.controls['cod_adicional2'].setValue(data.cod_adicional2);
    this.reactiveForm.controls['choferes'].setValue(data.cod_chofer);
    this.reactiveForm.controls['clv_confirma_combustible'].setValue(data.clv_confirma_combustible == 1 ? true : false);
    this.reactiveForm.controls['clv_confirma_casetas'].setValue(data.clv_confirma_casetas == 1 ? true : false);
    this.reactiveForm.controls['clv_retardo'].setValue(data.clv_retardo == 1 ? true : false);
    this.reactiveForm.controls['id_ruta_travel'].setValue(data.id_ruta_travel);
    this.reactiveForm.controls['id_tipo_aprovechamiento'].setValue(data.id_tipo_aprovechamiento);
    if (this.lstChoferesDisponibles.length > 0) {
      this.lstChoferesDisponibles.map((valor) => {
        valor.disabled = true;
      });
    }
    this.DilogEditar = true;
  }


  consultaInformacionDeUnidades(data: IOperacionDia) {
    this.model = {
      id_registro: data.idx_pao_diario,
      id_tipo_transporte: data.id_tipo_transporte,
      id_ciudad: data.id_ciudad,
      id_ruta: data.id_ruta,
      fecha: data.fecha,
      clv_operacion: data.clv_operacion,
    };
    //Informacion a Mostrar en el encabezado
    this.nombre_ruta_Editable = data.nombre_ruta;
    this.fecha_dia_editable = data.fecha.substring(0, 10);

    //falta cargar el metodo si rowData.num_real es >0 . Sino no se carga
    if (data.num_real > 0) {
      this.cargarInformacionDeMovDiarioUnidades(data.idx_pao_diario, data.id_ruta, data.fecha, data.nombre_proyecto);


    }

    //variables para el  editar la fecha de 3 dias
    let var1 = new Date(this.fecha_filtrada);
    let var2 = new Date(this.fecha_dia_final);

    //funcionalidad para poder editar en el lapso de 3 dias itzael
    if (this.model.clv_operacion == 3) {
      this.permite_editar = false;

    }
    else {
      this.permite_editar = true;

    }

    if (this.model.clv_operacion == 1) {
      this.permite_editar = (var1 < var2 || var1 > var2);
    }
    //permite editar para usuarios no administradores
    if (this.model.clv_operacion == 3) {
      this.permite_eliminar = false;
    }
    else if (this.model.clv_operacion == 1) {

      this.permite_eliminar = true;
    }

    if (this.model.clv_operacion == 1 && (this.fecha_filtrada == this.fecha_dia_final)) {
      this.permite_eliminar = false;
    }

  }

  cancelarAgregarUnidadyChofer() {
    this.dspEditar = false;
    this.clv_especial = 0;
    this.id_pao_unidades = 0;
    //limpiar formulario
    this.DilogEditar = false;
    this.reactiveForm.reset();

  }

  cancelarMostrarConsulta() {
    this.dspEditarConsulta = false;
    this.loading = false;
    this.id_pao_unidades = 0;
    this.tipo_aprovechamiento = '';
  }

  limpiarfiltrosTabla() {
    this.dt.clear();
  }
}
