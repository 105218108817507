import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { IFolioUnidad } from '../models/foliounidades';
import { Ifolios } from '../models/foliosunidadesindividuales';
import { IUnidadessinfolio } from '../models/unidadesinfolio';
@Injectable({
  providedIn: 'root'
})
export class FolioUnidadesService {
  private apiUrl: string ='';
  constructor(private _http: HttpClient) {
    this.apiUrl = environment.SUrlgApi + 'FolioUnidades';
  }

  getFolioUnidades(): Observable<IFolioUnidad[]> {
    return this._http
      .get<IFolioUnidad[]>(this.apiUrl + '/getFolioUnidades/' )
      .pipe(retry(1), catchError(this.handleError));
  }


 public guardarFolioRuta(data:any):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this._http.post<any>(this.apiUrl + '/pFoluni/',body,{'headers':headers});
   }

   public guardarFolioRutaeditar(data:any):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this._http.post<any>(this.apiUrl + '/pFoluniedit/',body,{'headers':headers});
   }

   getFolioUnidadesindividuales(num_economico: string , id_unidad_transporte :number ): Observable<Ifolios[]> {
    const valores = {
      params: new HttpParams().
        set('num_economico', num_economico).
        set('id_unidad_transporte', id_unidad_transporte)
    };
    return this._http
      .get<Ifolios[]>(this.apiUrl + '/getfolindi/', valores )
      .pipe(retry(1), catchError(this.handleError));
  }


  getvalidacionsinfolios(num_anio: string , num_mes :number ): Observable<IUnidadessinfolio[]> {
    const valores = {
      params: new HttpParams().
        set('num_anio', num_anio).
        set('num_mes', num_mes)
    };
    return this._http
      .get<IUnidadessinfolio[]>(this.apiUrl + '/getvalidacionfolios/',valores )
      .pipe(retry(1), catchError(this.handleError));
  }


   getListadoFolioMensual(num_mes: number,num_anio:number): Observable<any[]> {
      const valores = {
        params: new HttpParams().
          set('num_mes', num_mes).
          set('num_anio', num_anio)
      };
      return this._http
        .get<any[]>(this.apiUrl + '/rptListadoFolioMensual',valores);
    }


  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
