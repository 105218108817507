import { Component, OnInit, HostListener } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { IDefaultDataRmOp, IDataRmOperadores, IDataRmOpServicios } from '../models/rendimientoOperadores';
import { RendimientoOperadoresService } from '../services/rendimientoOperadores.service';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { IDefaultLessDataGD } from '../models/defaultGD';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rrdmop',
  templateUrl: './rrdmop.component.html',
  styleUrls: ['./rrdmop.component.scss']
})

export class RrdmopComponent implements OnInit {
  isMobile: boolean = false;
  @ViewChild('dt1') dt!: Table;
  months: IDefaultLessDataGD[] = [];
  selectMonth = '';
  year: IDefaultLessDataGD[] = [];
  selectYear: any;
  city: IDefaultLessDataGD[] = [];
  selectCity = '';
  unitconf: IDefaultLessDataGD[] = [];
  selectConf = '';

  urlGeneral = '';
  urlOperators = '';

  dataOk: Boolean = false;

  lsResInfo: IDefaultDataRmOp = {};
  lsRmOper: IDataRmOperadores[] = [];
  lsRmOpRut: IDataRmOpServicios[] = [];
  loading: boolean = false;

  constructor(
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private rendimientoOperadores: RendimientoOperadoresService) {
  }

  ngOnInit(): void {
    this.months = [
      { id: 1, nombre: 'ENERO' },
      { id: 2, nombre: 'FEBRERO' },
      { id: 3, nombre: 'MARZO' },
      { id: 4, nombre: 'ABRIL' },
      { id: 5, nombre: 'MAYO' },
      { id: 6, nombre: 'JUNIO' },
      { id: 7, nombre: 'JULIO' },
      { id: 8, nombre: 'AGOSTO' },
      { id: 9, nombre: 'SEPTIEMBRE' },
      { id: 10, nombre: 'OCTUBRE' },
      { id: 11, nombre: 'NOVIEMBRE' },
      { id: 12, nombre: 'DICIEMBRE' },
    ];

    this.getCityAndConfUnitInformacion()

    this.checkScreenSize();
  }

  getCityAndConfUnitInformacion() {
    this.rendimientoOperadores.getCiudadesYConfiguracionesUnidades().subscribe((resp) => {
      if (resp.status) {
        this.city = resp.data.cities;
        this.unitconf = resp.data.conf;
      }
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> Al cargar el listado de herramientas seleccionadas de la unidad');
      });

    (document.getElementById('button_search_unit') as HTMLButtonElement).disabled = false
  }

  searchUnitAction() {
    this.dataOk = false;
    this.urlGeneral = '';
    this.urlOperators = '';
    (document.getElementById('button_search_unit') as HTMLButtonElement).disabled = true

    if (this.selectMonth == '' || this.selectMonth == null || this.selectYear == '' || this.selectYear == null || this.selectCity == '' || this.selectCity == null || this.selectConf == '' || this.selectConf == null) {
      (document.getElementById('button_search_unit') as HTMLButtonElement).disabled = false
      this.messageService.add({ severity: 'warn', summary: 'Campos incorrectos', detail: 'Debes seleccionar una configuracion valida.' });
    } else {
      this.getResumenRendimientoOperadores(this.selectMonth, this.selectYear.getFullYear(), this.selectCity, this.selectConf)
    }
  }

  getResumenRendimientoOperadores(month: String, year: String, city: String, conf: String) {
    this.loading = true;

    this.rendimientoOperadores.getResumenRendimientoOperaciones(month, year, city, conf).subscribe((resp) => {
      if (resp.status) {
        if (resp.data === null) {
          this.loading = false;
          this.clearData();
          this.messageService.add({ severity: 'info', summary: 'No hay informacion', detail: 'No cuenta con informacion esta configuracion.' });
        } else {
          if (resp.data.num_total_rutas == 0) {
            this.loading = false;
            this.clearData();
            this.messageService.add({ severity: 'info', summary: 'Sin rutas', detail: 'No cuenta con rutas disponibles.' });
          } else {
            this.loading = false;
            this.lsResInfo = resp.data;

            if (resp.data.jsn_operadores != null) {
              this.lsRmOper = resp.data.jsn_operadores;
              this.dataOk = true;

              this.urlGeneral = environment.sUrlGd + 'performance/operator/xls?type=1&month=' + this.selectMonth + '&year=' + this.selectYear.getFullYear() + '&city=' + this.selectCity + '&unit=' + this.selectConf
              this.urlOperators = environment.sUrlGd + 'performance/operator/xls?type=2&month=' + this.selectMonth + '&year=' + this.selectYear.getFullYear() + '&city=' + this.selectCity + '&unit=' + this.selectConf
            }
          }
        }
      }
    },
      (error) => {
        this.loading = false;
        this.clearData();
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> Al cargar el listado de herramientas seleccionadas de la unidad');
      });

    (document.getElementById('button_search_unit') as HTMLButtonElement).disabled = false
  }

  chargeOpServInfo(data: IDataRmOpServicios[]) {
    this.lsRmOpRut = data;

    const moduleMain = document.getElementById('module_main_rmop')
    const moduleOperator = document.getElementById('module_operator_rmop')

    if (moduleMain && moduleOperator) {
      moduleMain.style.display = "none";
      moduleOperator.style.display = "block";
    }
  }

  hideServResume() {
    const moduleMain = document.getElementById('module_main_rmop')
    const moduleOperator = document.getElementById('module_operator_rmop')

    if (moduleMain && moduleOperator) {
      moduleMain.style.display = "block";
      moduleOperator.style.display = "none";
    }
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Verifique la informacion.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  validateUrl(event: Event) {
    if (this.urlGeneral == '' || this.urlOperators == '') {
      event.preventDefault();
      alert('Debes buscar una ruta valida')
    }
  }

  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  formatCurrency(number: any) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  }

  formatNumber(number: any) {
    return new Intl.NumberFormat('en-US').format(number);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isMobile = window.innerWidth < 768; // Cambia el valor según tus necesidades
  }

  clearData() {
    this.urlGeneral = '';
    this.urlOperators = '';
    this.dataOk = false;
    this.lsResInfo = {};
    this.lsRmOper = [];
    this.lsRmOpRut = [];
    this.loading = false;
  }
}
