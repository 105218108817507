<p-toast position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}"  key="kc" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
    rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false">
</p-confirmDialog>
<div class="container-fluid" style="border-color: blue; border: 5px ; width: 90%;">
    <div class="row">
        <div class="col-md-12">
            <div class="card card-shadow sinborde">
                <div class="card-header sinborde" style="background: none; ">
                    <div class="cardicono rounded">
                        <i class="pi pi-history mt-3" style="font-size: 2rem; color: #fff;"></i>
                    </div>
                    <header style="left: 11%; top: 5%;">Consulta de Movimientos
                        <div style="color:blueviolet; display: inline;">Operación Diaria</div>
                    </header>
                    <div class="row d-flex justify-content-end">
                        <div class="col-10">
                            <div class="form-group row mt-5">
                                <div class="col-5">
                                    <label for="lblligero" class="col-sm-4 col-form-label color-font text-right"
                                        style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                                        <span class="input-group-addon"> <i class="pi pi-calendar color-icon mr-3"
                                                style="font-size: 2rem; color: #3B82F6;"></i>
                                        </span>
                                        Fecha</label>
                                    <div class="col-sm-8">
                                        <input type="date" class="form-control sinborde"
                                            pTooltip="Seleccione o teclee fecha" tooltipPosition="top"
                                            [(ngModel)]="fecha_dia">
                                        <hr class="my-0">
                                    </div>
                                </div>
                                <div class="col-5">
                                    <label for="lblligero" class="col-sm-4 col-form-label color-font text-right"
                                        style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                                        <span class="input-group-addon"> <i class="pi pi-calendar color-icon mr-3"
                                                style="font-size: 2rem; color: #3B82F6;"></i>
                                        </span>
                                        Fecha Fin</label>
                                    <div class="col-sm-8">
                                        <input type="date" class="form-control sinborde"
                                            pTooltip="Seleccione o teclee fecha fin para filtrar por Rango"
                                            tooltipPosition="top" [(ngModel)]="fecha_fin">
                                        <hr class="my-0">
                                    </div>
                                </div>
                                <div class="col-2 d-flex align-items-end">
                                    <button pButton pRipple type="button"
                                        (click)="cargarInformacionPorFecha(fecha_dia,fecha_fin);" label="Filtrar"
                                        icon="pi pi-filter" [style]="{'width':'100%'}" iconPos="left"
                                        class="info"></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 d-flex align-items-center">
                            <button *ngIf="mostrarbtnLimpiarFiltro" pButton pRipple type="button"
                                (click)="LimpiarFiltro();" label="Limpiar Filtro"
                                class="p-button-raised p-button-warning" icon="pi pi-filter-slash" iconPos="left"
                                pTooltip="Reiniciar Filtro" tooltipPosition="top"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <p-table #dt1 [value]="lstMovimientos" [scrollable]="true" scrollHeight="flex" scrollDirection="both"
            [paginator]="true" [rows]="150" [rowsPerPageOptions]="[150,200,300,500]" [showCurrentPageReport]="true"
            currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
            [globalFilterFields]="['num_economico','nombre_chofer','num_folio','desc_tipo_aprovechamiento']"
            [loading]="loading">
            <ng-template pTemplate="caption">
                <div class="flex justify-content-between">
                    <button type="button" pButton pRipple class="p-button-success ml-5"
                        pTooltip="Exportar TODOS los Movimientos a EXCEL" tooltipPosition="right"
                        (click)="exportarExcel();" [disabled]="filtered">
                        <i class="pi pi-file-excel">Exportar</i>
                    </button>
                    <button type="button" pButton pRipple class="p-button-success ml-5"
                        pTooltip="Exportar fecha de registro de Recursos" tooltipPosition="right"
                        (click)="exportarExcelRecursos();" [disabled]="filtered">
                        <i class="pi pi-file-excel">Exportar Recursos</i>
                    </button>
                    <button type="button" class="btn btn-success"
                                (click)="abrirModalReportes()" pTooltip="Abrir Ventana" tooltipPosition="left">
                                Reportes <i class="pi pi-file-excel"></i>
                    </button>
                    <span class="p-input-icon-left p-ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                            placeholder="Buscar Chofer,Numero Economico,Folio" />
                    </span>
                </div>
                <div class="row d-flex justify-content-end mt-5 mb-5">
                    <div class="col-md-3">
                        <p-columnFilter type="text" field="desc_ruta" placeholder="Ruta"
                            class="form-control sinborde filtro" matchMode="contains"></p-columnFilter>
                    </div>
                    <div class="col-md-3">
                        <p-columnFilter type="text" field="desc_cliente" placeholder="Cliente"
                            class="form-control sinborde filtro" matchMode="contains"></p-columnFilter>
                    </div>
                    <div class="col-md-3">
                        <p-columnFilter type="text" field="desc_proyecto" placeholder="Proyecto"
                            class="form-control sinborde filtro" matchMode="contains"></p-columnFilter>
                    </div>
                    <div class="col-md-3">
                        <p-columnFilter type="text" field="desc_ciudad" placeholder="Ciudad"
                            class="form-control sinborde filtro" matchMode="contains"></p-columnFilter>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 100px;">#</th>
                    <th style="width: 200px; min-width: 200px;" pSortableColumn="desc_ruta">Ruta<p-sortIcon
                            field="desc_ruta"></p-sortIcon></th>
                    <th style="width: 300px;" pSortableColumn="desc_tiporuta">Tipo de Ruta<p-sortIcon
                            field="desc_tiporuta"></p-sortIcon></th>
                    <th style="width: 150px;min-width: 150px;">Ciudad</th>
                    <th style="width: 100px;" pSortableColumn="num_economico">#Economico<p-sortIcon
                            field="num_economico"></p-sortIcon></th>
                    <th style="width: 300px;">Generales</th>
                    <th style="width: 120px;min-width: 120px;" pSortableColumn="nombre_chofer">Colaborador<p-sortIcon
                            field="nombre_chofer"></p-sortIcon></th>
                    <th style="width: 100px;">Fecha</th>
                    <th style="width: 200px;" pSortableColumn="clv_fin_ruta"><p-sortIcon
                        field="clv_fin_ruta"></p-sortIcon></th>
                    <th style="width: 100;" *ngIf="clv_operacion_fechas == 3"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                <tr>
                    <td style="width: 100px;">{{ (rowIndex+1) }}</td>
                    <td style="width: 300px;" [ngClass]="{'col-header-sp': data.clv_especial==1}">{{data.desc_ruta}}
                        <p style="display: inline-block; color: green !important;font-weight: bold;">
                             {{ data.desc_folio}}</p>
                        <p  *ngIf="data.clv_especial==2" style="display: inline-block; color: orange !important;font-weight: bold;">
                        SIN COBRO</p>
                        <p  *ngIf="data.clv_especial==3" style="display: inline-block; color: orange !important;font-weight: bold;">
                            SIN NOMINA</p>



                    </td>
                    <td  style="width: 300px;" [ngClass]="{'col-header-sp': data.clv_especial==1}">{{data.desc_tiporuta}}
                        <p *ngIf="data.clv_cancelado==1" class="col-orange col-md-6 mt-2"
                        pTooltip="La ruta se marco como Cancelada por Operaciones">CANCELADA</p>
                        <p   style="display: inline-block; color: #336b87 !important;font-weight: bold;">
                          {{data.desc_tipo_aprovechamiento}}</p>
                    </td>
                    <td style="width: 150px;min-width: 150px;">{{data.desc_ciudad}}</td>
                    <td style="width: 100px; color: green; font-weight: bold;">{{data.num_economico}}</td>
                    <td style="width: 300px">{{data.datos_generales}}</td>
                    <td style="width: 120px;min-width: 120px; color: blueviolet; font-weight: bold; font-size: 12px;">
                        <ol>
                            <li>{{'(Principal) '+data.nombre_chofer}}</li>
                            <li *ngIf="data.id_puesto1!=0">{{'('+data.desc_puesto_adicional1+') '+data.nombre_completo_adicional1}}</li>
                            <li *ngIf="data.id_puesto2!=0">{{'('+data.desc_puesto_adicional2+') '+data.nombre_completo_adicional2}}</li>
                        </ol>
                    </td>
                    <td style="width: 100px;">
                        <div>{{data.fecha | date: 'dd/MM/yyyy'}}</div>
                        <div class="col-hora text-center">{{data.hora}}</div>
                    </td>
                    <td style="width: 200px;">
                        <div *ngIf="data.clv_operacion==1 || data.clv_operacion==3">
                            <div class="d-flex justify-content-center">
                                <p-button label="Gestión" icon="pi pi-eraser"
                                    styleClass="p-button-raised p-button-secondary"
                                    pTooltip="Recursos" tooltipPosition="top"
                                    (onClick)="mostrarRecursos(data)"></p-button>
                            </div>
                            <div class="d-flex justify-content-around mt-2">
                                <p-tag *ngIf="data.fecha<=fecha_dia_real && data.clv_fin_ruta==0" severity="warning" value="EN TRANSITO"></p-tag>
                            </div>
                            <div class="d-flex justify-content-around mt-2">
                                <p-tag *ngIf="data.clv_fin_ruta==1" severity="success" value="FINALIZADA"></p-tag>
                                 <p-tag *ngIf="data.clv_fin_ruta==0" severity="danger" value="NO FINALIZADA"></p-tag>
                            </div>
                        </div>
                    </td>
                    <td style="width: 100px;" *ngIf="clv_operacion_fechas == 3">
                        <!-- <div *ngIf="data.clv_operacion==1 || data.clv_operacion==3"> -->
                            <div class="d-flex justify-content-center">
                                <p-button label="Fechas" icon="pi pi-pencil"
                                    styleClass="p-button-raised p-button-secondary" pTooltip="Gestión de Fechas"
                                    tooltipPosition="top"
                                    (onClick)="mostrarFechasFin(data.idx)"></p-button>
                            </div>
                        <!-- </div> -->
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <!--ADMINISTRADOR DE RECURSOS-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspRecursos"
        [style]="{width: '95vw','height':'85vh'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
        (onHide)="cancelarModal()">
        <ng-template pTemplate="header">
            <div class="d-flex justify-content-start">
                <div class="col-md-12">Registro de Recursos y Kilometraje de Ruta {{texto_fecha_recurso | date: 'dd/MM/yyyy'}}</div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="mt-3">
                <div class="row">
                    <div class="col-md-12 texto-lila d-flex justify-content-between">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <p class="d-flex align-items-center texto-naranja">Numero Economico: {{recurso.num_economico}}</p>
                                </div>
                            </div>
                            <div class="row texto-black">
                                <div class="col-md-4">
                                    <p class="d-flex align-items-center">Fijo: {{ num_tag_fijo}}</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="d-flex align-items-center">Emergente: {{num_tag_emergente}}</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="d-flex align-items-center">Sodexo: {{num_tarjeta_sodexo}}</p>
                                </div>
                            </div>
                        </div>
                        <div class=" col-md-6 ">
                            <div class="row"  *ngIf="clv_operacion_fechas != 3">
                                    <div class="row">
                                        <p class="col-md ">{{messageFin}}</p>
                                        <div class="col-md" *ngIf="clv_operacion==1">
                                            <p-button label="Finalizar Ruta" (onClick)="validarInformacionOdometro()" [disabled]="recurso.clv_fin_ruta==1 || btnHabilitarGestion"></p-button>
                                        </div>
                                        <div class="col-md" *ngIf="clv_operacion==3" >
                                            <p-button label="Finalizar Ruta" (onClick)="validarInformacionOdometro()" [disabled]="btnHabilitarGestion"></p-button>
                                        </div>
                                    </div>
                            </div>
                            <div class="row"  *ngIf="clv_operacion_fechas == 3">
                                <div class="col-md-6 d-inline justify-content-center">
                                    <div class="row">
                                        <p class="col-md ">{{messageFin}}</p>
                                        <div class="col-md" *ngIf="clv_operacion==1">
                                            <p-button label="Finalizar Ruta" (onClick)="validarInformacionOdometro()" [disabled]="recurso.clv_fin_ruta==1 || btnHabilitarGestion"></p-button>
                                        </div>
                                        <div class="col-md" *ngIf="clv_operacion==3" >
                                            <p-button label="Finalizar Ruta" (onClick)="validarInformacionOdometro()" [disabled]="btnHabilitarGestion"></p-button>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-6 d-inline">
                                    <div class="row align-items-end" >
                                        <p class="col-md">Editar Fechas</p>
                                        <div class="col-md">
                                            <p-button icon="pi pi-clock" (onClick)="validarInformacionOdometro()" [disabled]="btnHabilitarGestion"></p-button>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <form [formGroup]="dataForm" (ngSubmit)="guardarInformacion()">
                    <div class="row">
                        <div class="col-md-6">
                            <span class="title-light mt-3">Información de Recursos de Ruta <i class="pi pi-cog"
                                    style="size: 2rem;"></i>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <!--APARTADO CASETAS-->
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-sm-5">
                                    <p class="m-0 texto-valido">CASETAS</p>
                                </div>
                                <div class="col-sm-7">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p class="d-flex align-items-center col-orange">{{messageFinCasetas}}</p>
                                        </div>
                                        <div class="col-md-6">
                                            <p-button *ngIf="clv_operacion==1" label="FinCasetas" styleClass="p-button-help" (onClick)="validarInformacionCasetas()" [disabled]="recurso.clv_fin_casetas==1 || btnHabilitarGestion"></p-button>
                                            <p-button *ngIf="clv_operacion==3" label="FinCasetas" styleClass="p-button-help" (onClick)="validarInformacionCasetas()" [disabled]="btnHabilitarGestion"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--APARTADO CASETAS-->
                            <div class="row mt-2">
                                <div class="row mb-1">
                                    <div class="col-sm-1 mx-0">
                                        <span class="input-group-addon"> <i class="pi pi-align-justify color-icon"
                                                style="font-size: 2rem"></i>
                                        </span>
                                    </div>
                                    <div class="col-sm-11">
                                        <p-dropdown [options]="lstPagoCaseta" optionLabel="nombre" autoWidth="false"
                                            [style]="{'width':'100%'}" placeholder="Seleccione Tipo Pago*"
                                            optionValue="id" formControlName="SelectedPagoCaseta"></p-dropdown>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-4 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-dollar color-icon"
                                                    style="font-size: x-small;"></i>
                                            </span>
                                            <p class="d-inline" style="font-size: x-small;">Caseta</p>
                                        </div>
                                        <div class="col-sm-8">
                                            <input type="number" class="form-control" placeholder="Caseta"
                                                formControlName="imp_caseta" style="font-size: x-small;" min="0"
                                                pTooltip="Precio Caseta" tooltipPosition="bottom">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="row g-0">
                                        <div class="col-sm-6 mx-0">
                                            <p-button icon="pi pi-plus" iconPos="right" (click)="agregarCasetas()"
                                                styleClass="p-button-sm" pTooltip="Registrar Caseta" [disabled]="(habilitar_tres_dias && clv_operacion==1) || btnHabilitarGestion || recurso.clv_fin_casetas==1"></p-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-12">
                                        <p-table #myTab [value]="listCasetas"
                                            [tableStyle]="{ 'min-width': '30rem','height':'200px' }"
                                            styleClass="p-datatable-sm" [scrollable]="true" scrollHeight="flex"
                                            scrollHeight="200px"
                                            [loading]="loading_caseta">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Importe</th>
                                                    <th>Tipo de Pago</th>
                                                    <th>Info</th>
                                                    <th></th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                                                <tr *ngIf="data.clv_guardado==0">
                                                    <td class="col-hora">{{ (rowIndex+1) }}</td>
                                                    <td>{{ data.imp_caseta | currency }}</td>
                                                    <td class="texto-lila">{{ data.desc_pago }}
                                                        <p class="d-inline" style="font-size: 9px;">{{ data.fec_registro | date: 'dd/MM/yyyy' }}</p>
                                                    </td>
                                                    <td>
                                                        <p class="col-orange-sm my-0">
                                                            Sin guardar
                                                        </p>
                                                    </td>
                                                    <td class="g-0">
                                                        <p-button icon="pi pi-trash" *ngIf="clv_operacion==1"
                                                            styleClass="p-button-rounded p-button-warning p-button-text"
                                                            (click)="eliminarCasetas(data)"
                                                            pTooltip="eliminar local"
                                                            [disabled]="(data.clv_guardado==1 || btnHabilitarGestion)"></p-button>
                                                        <p-button icon="pi pi-trash" *ngIf="clv_operacion==3"
                                                            styleClass="p-button-rounded p-button-warning p-button-text"
                                                            pTooltip="Admin-local"
                                                            (click)="eliminarCasetas(data)"
                                                            [disabled]="(btnHabilitarGestion)"></p-button>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="data.clv_guardado==1">
                                                    <td class="col-hora">{{ (rowIndex+1) }}</td>
                                                    <td>{{ data.imp_caseta | currency }}</td>
                                                    <td class="texto-lila">{{ data.desc_pago }}
                                                        <p class="d-inline" style="font-size: 9px;">{{ data.fec_registro | date: 'dd/MM/yyyy' }}</p>
                                                    </td>
                                                    <td style="font-size: 9px;">
                                                        <p pTooltip="Registro" tooltipPosition="left">{{ data.nom_usuario  }}</p>
                                                    </td>
                                                    <td class="g-0">
                                                        <p-button icon="pi pi-trash" *ngIf="clv_operacion==1"
                                                            styleClass="p-button-rounded p-button-danger p-button-text"
                                                            (click)="eliminarCasetas(data)"
                                                            pTooltip="Info guardada"
                                                            [disabled]="(data.clv_guardado==1 || btnHabilitarGestion)"></p-button>
                                                        <p-button icon="pi pi-trash" *ngIf="clv_operacion==3"
                                                            styleClass="p-button-rounded p-button-danger p-button-text"
                                                            pTooltip="Admin-eliminar"
                                                            (click)="eliminarCasetas(data)"
                                                            [disabled]="(btnHabilitarGestion)"></p-button>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7"><!--APARTADO COMBUSTIBLE-->
                            <div class="row">
                                <div class="col-sm-5">
                                    <p class="m-0 texto-valido">COMBUSTIBLE</p>
                                </div>
                                <div class="col-sm-7">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p class="d-flex align-items-center col-orange">{{messageFinCombustible}}</p>
                                        </div>
                                        <div class="col-md-6">
                                            <p-button *ngIf="clv_operacion==1" label="FinCombustible" styleClass="p-button-help" (onClick)="validarInformacionCombustible()" [disabled]="recurso.clv_fin_combustible==1 || btnHabilitarGestion"></p-button>
                                            <p-button *ngIf="clv_operacion==3" label="FinCombustible" styleClass="p-button-help" (onClick)="validarInformacionCombustible()" [disabled]="btnHabilitarGestion"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="row mb-1">
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-2 d-flex align-items-center">
                                                <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                        style="font-size: 1.5rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-10">
                                                <p-dropdown [options]="lstPagoCumbustible" optionLabel="nombre"
                                                autoWidth="false" [style]="{'width':'100%'}"
                                                placeholder="Seleccione Tipo Pago*" optionValue="id"
                                                formControlName="SelectedPagoCombustible"></p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-2 d-flex align-items-center">
                                                <span class="input-group-addon"> <i class="pi pi-bars color-icon"
                                                        style="font-size: 1.5rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-10">
                                                <p-dropdown [options]="lstCombustible" optionLabel="nombre"
                                                autoWidth="false" [style]="{'width':'100%'}"
                                                placeholder="Seleccione Combustible*" optionValue="id"
                                                formControlName="SelectedTipoCombustible"></p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <div class="row g-0">
                                            <div class="col-sm-4 mx-0">
                                                <p class="d-inline" style="font-size: x-small;">Litros</p>
                                            </div>
                                            <div class="col-sm-8">
                                                <input type="number" class="form-control" placeholder="Numero de Litros"
                                                    formControlName="num_litro" style="font-size: x-small;" min="0"
                                                    pTooltip="Litro">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <div class="row g-0">
                                            <div class="col-sm-4 mx-0">
                                                <p class="d-inline" style="font-size: x-small;">Precio</p>
                                            </div>
                                            <div class="col-sm-8">
                                                <input type="number" class="form-control" placeholder="Importe de Litro"
                                                    formControlName="imp_litro" style="font-size: x-small;" min="0"
                                                    pTooltip="Precio de Litro">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <div class="row g-0">
                                            <div class="col-sm-4 mx-0">
                                                <p class="d-inline" style="font-size: x-small;">Total</p>
                                            </div>
                                            <div class="col-sm-8">
                                                <input type="number" class="form-control" placeholder="Total"
                                                    formControlName="imp_combustible" style="font-size: x-small;"
                                                    pTooltip="Total">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="form-group col-md-5">
                                        <div class="row g-0">
                                            <div class="col-sm-4 mx-0">
                                                <p class="d-inline" style="font-size: x-small;">Kim.Inicial</p>
                                            </div>
                                            <div class="col-sm-8">
                                                <input type="number" class="form-control" placeholder="Kim.Inicial"
                                                    formControlName="kim_inicial_carga" style="font-size: x-small;" min="0"
                                                    pTooltip="Inicial">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-5">
                                        <div class="row g-0">
                                            <div class="col-sm-4 mx-0">
                                                <p class="d-inline" style="font-size: x-small;">Kim.Final</p>
                                            </div>
                                            <div class="col-sm-8">
                                                <input type="number" class="form-control" placeholder="Kim.Final"
                                                    formControlName="kim_final_carga" style="font-size: x-small;"
                                                    pTooltip="Final">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-2">
                                        <div class="row g-0">
                                            <div class="col-sm-12 mx-0">
                                                <p-button icon="pi pi-plus" iconPos="right" (click)="agregarCombustible()"
                                                    styleClass="p-button-sm" pTooltip="Registrar Combustible" [disabled]="(habilitar_tres_dias && clv_operacion==1) || btnHabilitarGestion || recurso.clv_fin_combustible==1"></p-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-12">
                                        <p-table [value]="listCombustible"
                                            [tableStyle]="{ 'min-width': '30rem','height':'200px' }"
                                            styleClass="p-datatable-sm" [scrollable]="true" scrollHeight="flex"
                                            scrollHeight="200px"
                                            [loading]="loading_combustible">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Litros</th>
                                                    <th>Precio</th>
                                                    <th>Total</th>
                                                    <th>Pago</th>
                                                    <th>Km.Inicial</th>
                                                    <th>Km.Final</th>
                                                    <th>Recorridos</th>
                                                    <th>Rendimiento</th>
                                                    <th>Info</th>
                                                    <th></th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                                                <tr *ngIf="data.clv_guardado==0">
                                                    <td class="col-hora">{{ (rowIndex+1) }}</td>
                                                    <td>{{ data.num_litros_combustible }}</td>
                                                    <td>{{ data.imp_litro | currency }}</td>
                                                    <td>{{ data.imp_total_combustible | currency }}</td>
                                                    <td class="texto-lila">{{ data.desc_pago }}
                                                        <p class="d-inline" style="font-size: 9px;">{{ data.fec_registro | date: 'dd/MM/yyyy' }}</p>
                                                    </td>
                                                    <td>{{ data.kim_inicial_carga | thousand}}</td>
                                                    <td>{{ data.kim_final_carga | thousand}}</td>
                                                    <td>
                                                        <p *ngIf="(data.num_rendimiento!=-1 && data.tipo_combustible!=4)" class="texto-black"> {{ data.kim_recorridos | thousand }}</p>
                                                        <p *ngIf="data.num_rendimiento==-1 || (data.num_rendimiento==0 && data.clv_guardado==0)" class="col-orange-sm my-0">
                                                            Sin guardar
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p *ngIf="(data.num_rendimiento!=-1 && data.tipo_combustible!=4)" class="texto-black"> {{ data.num_rendimiento | number:'1.2'  }}</p>
                                                        <p *ngIf="data.num_rendimiento==-1 || (data.num_rendimiento==0 && data.clv_guardado==0)" class="col-orange-sm my-0">
                                                            Sin guardar
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p class="col-orange-sm my-0">
                                                            Sin guardar
                                                        </p>
                                                    </td>
                                                    <td class="g-0">
                                                        <p-button icon="pi pi-trash" *ngIf="clv_operacion==1"
                                                            styleClass="p-button-rounded p-button-warning p-button-text"
                                                            (click)="eliminarCombustible(data)"
                                                            [disabled]="(data.clv_guardado==1 || btnHabilitarGestion)"
                                                            pTooltip="Eliminar local"></p-button>
                                                        <p-button icon="pi pi-trash" *ngIf="clv_operacion==3"
                                                            styleClass="p-button-rounded p-button-warning p-button-text"
                                                            pTooltip="Admin-local"
                                                            (click)="eliminarCombustible(data)"
                                                            [disabled]="(btnHabilitarGestion)"></p-button>
                                                    </td>
                                                </tr>
                                                <!--INFORMACIÓN YA ALMACENADA DE LA RUTA-->
                                                <tr *ngIf="data.clv_guardado==1">
                                                    <td class="col-hora">{{ (rowIndex+1) }}</td>
                                                    <td>{{ data.num_litros_combustible }}</td>
                                                    <td>{{ data.imp_litro | currency }}</td>
                                                    <td>{{ data.imp_total_combustible | currency }}</td>
                                                    <td class="texto-lila">{{ data.desc_pago+'('+data.nom_combustible+')' }}
                                                        <p class="d-inline" style="font-size: 9px;">{{ data.fec_registro | date: 'dd/MM/yyyy' }}</p>
                                                    </td>
                                                    <td>{{ data.kim_inicial_carga | thousand}}</td>
                                                    <td>{{ data.kim_final_carga | thousand}}</td>
                                                    <td>{{ data.kim_recorridos | thousand }}</td>
                                                    <td class="texto-black">{{ data.num_rendimiento | number:'1.2' }}</td>
                                                    <td style="font-size: 9px; color: brown;">
                                                       <p pTooltip="Registro" tooltipPosition="left">{{ data.nom_usuario  }}</p>
                                                    </td>
                                                    <td class="g-0">
                                                        <p-button icon="pi pi-trash" *ngIf="clv_operacion==1"
                                                            styleClass="p-button-rounded p-button-danger p-button-text"
                                                            (click)="eliminarCombustible(data)"
                                                            pTooltip="Info guardada"
                                                            [disabled]="(data.clv_guardado==1 || btnHabilitarGestion)"></p-button>
                                                        <p-button icon="pi pi-trash" *ngIf="clv_operacion==3"
                                                            styleClass="p-button-rounded p-button-danger p-button-text"
                                                            pTooltip="Admin-eliminar"
                                                            (click)="eliminarCombustible(data)"
                                                            [disabled]="(btnHabilitarGestion)"></p-button>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="mt-5">
                    <div class="row mt-2"> <!--KILOMETRAJE-->
                        <div class="form-group col-md-6">
                            <div class="row g-0">
                                <div class="col-sm-4 mx-0">
                                    <span class="input-group-addon"> <i class="pi pi-map-marker color-icon"
                                            style="font-size: 1.5rem"></i>Kim.Inicial
                                    </span>
                                </div>
                                <div class="col-sm-8">
                                    <input type="number" class="form-control" placeholder="Kilometraje Inicial"
                                        formControlName="kim_inicial" style="text-transform:uppercase;" min="0"
                                        pTooltip="Kilometraje Inicial" tooltipPosition="left">
                                    <hr class="my-0">
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <div class="row g-0">
                                <div class="col-sm-4 mx-0">
                                    <span class="input-group-addon"> <i class="pi pi-map-marker color-icon"
                                            style="font-size: 1.5rem"></i>Kim.Final
                                    </span>
                                </div>
                                <div class="col-sm-8">
                                    <input type="number" class="form-control" placeholder="Kilometraje Final"
                                        formControlName="kim_final" style="text-transform:uppercase;" min="0"
                                        pTooltip="Kilometraje Final" tooltipPosition="top"
                                        >
                                    <hr class="my-0">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center mt-5">
                        <div class="col-2">
                            <button *ngIf="clv_operacion==1" type="submit" class="btn btn-success w-100"
                            [disabled]="(habilitar_tres_dias) || recurso.clv_fin_ruta==1 || btnHabilitar || btnHabilitarGestion"
                                pTooltip="Almacenar Toda la Informacion de los Recursos" tooltipPosition="bottom">
                                <span class="text-white">Guardar Todo</span>
                                <i class="pi pi-save text-white"></i>
                            </button>
                            <button *ngIf="clv_operacion==3" type="submit" class="btn btn-success w-100"
                                pTooltip="Almacenar Toda la Informacion de los Recursos" tooltipPosition="bottom" [disabled]="btnHabilitar || btnHabilitarGestion">
                                <span class="text-white">Guardar Todo</span>
                                <i class="pi pi-save text-white"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-2 text-center">
                            <i class="pi pi-spin pi-spinner" *ngIf="btnHabilitar"></i>
                        </div>
                    </div>
                </form>
                <div class="row d-flex justify-content-end mb-5">
                    <div class="col-4" *ngIf="mostrar_texto_fechas==1 && habilitar_tres_dias==true && clv_operacion==1">
                        <p class="text-justify" style="color: red; font-weight: bold;">*No se pueden editar registros de
                            Fechas Anteriores</p>
                    </div>
                    <div class="col-3">
                        <!--<button class="btn btn-warning w-100" (click)="habilitarEdicion();" *ngIf="clv_operacion==1"
                            [disabled]="((fecha_del_recurso<fecha_final_dia && clv_operacion==1) || clv_operacion==1 && recurso.clv_fin_ruta==1)"
                            pTooltip="Habilitar campos para actualizar Información.">
                            <span>Habilitar Edición</span>
                            <i class="pi pi-eraser"></i>
                        </button>-->
                        <button class="btn btn-warning w-100" (click)="habilitarEdicion();" *ngIf="clv_operacion==1"
                            [disabled]="( (habilitar_tres_dias) || ( recurso.clv_fin_ruta==1))"
                            pTooltip="Habilitar campos." tooltipPosition="left">
                            <span>Habilitar Edición</span>
                            <i class="pi pi-eraser"></i>
                        </button>
                        <button class="btn btn-warning w-100" (click)="habilitarEdicion();" *ngIf="clv_operacion==3"
                            pTooltip="Habilitar campos." tooltipPosition="left">
                            <span>Habilitar Edición</span>
                            <i class="pi pi-eraser"></i>
                        </button>
                    </div>
                </div>
                <div class="row d-flex justify-content-start">
                    <div class="col-6">
                        <p class="texto-black">*Rendimiento=(Kilómetros Recorridos)/Cantidad de litros de la carga.</p>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="cancelarModal();"></button>
        </ng-template>
    </p-dialog>
    <!--REPORTES-->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
    [style]="{width: '70vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
    (onHide)="cancelarModalReportes()">
    <ng-template pTemplate="header">
        Reportes
    </ng-template>
    <ng-template pTemplate="content">
        <div class="mt-5">
            <hr class="style-two">
            <div class="row mb-3">
                <div class="col-md-4">
                    <p class="texto-pesado">Rendimiento de Unidad Por Colaborador (Mes y Año)</p>
                </div>
                <div class="col-md-2">
                    <p-dropdown [options]="lstCiudades" optionLabel="nombre" placeholder="Ciudad*" autoWidth="false"
                        [style]="{'width':'100%'}" [showClear]="true" [(ngModel)]="selectedCiudad"
                        tooltip="Es la Ciudad a consultar" tooltipPosition="left"></p-dropdown>
                    <hr class="my-0">
                </div>
                <div class="col-md-2">
                    <p-dropdown [options]="meses" optionLabel="nombre" placeholder="Mes*" autoWidth="false"
                        [style]="{'width':'100%'}" [showClear]="true" [(ngModel)]="selectedMesReporte1"
                        tooltip="Es el Mes a consultar" tooltipPosition="left"></p-dropdown>
                    <hr class="my-0">
                </div>
                <div class="col-md-2">
                    <p-calendar [(ngModel)]="anioReporte1" [showIcon]="true" view="year" dateFormat="yy"
                        [style]="{'width':'100%'}" placeholder="Seleccione un año*"></p-calendar>
                    <hr class="my-0">
                </div>
                <div class="col-md-2">
                    <button type="button" class="btn btn-outline-success" style="width: 100%;"
                        (click)="generarReporte1()">
                        Exportar <i class="pi pi-file-export"></i>
                    </button>
                </div>
            </div>
            <hr class="my-0">
            <div class="row mt-3 mb-3">
              <div class="col-md-4">
                <p class="texto-pesado">Kilometraje</p>
              </div>
              <div class="col-md-3">
                <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha inicio"
                  tooltipPosition="top" [(ngModel)]="fec_dia_reporte">
                <hr class="my-0">
              </div>
              <div class="col-md-3">
                <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha fin"
                  tooltipPosition="top" [(ngModel)]="fec_fin_reporte">
                <hr class="my-0">
              </div>
              <div class="col-md-2">

                <button type="button" class="btn btn-outline-success" style="width: 100%;"
                  (click)="exportarExcelKilometros(fec_dia_reporte,fec_fin_reporte)" [disabled]="ButtonExportExcel">
                  Exportar <i class="pi pi-file-export"></i>
                </button>
              </div>
            </div>
            <hr class="my-0">
            <div class="row mt-3 mb-3">
                <div class="col-md-4">
                  <p class="texto-pesado">Movimientos de Operación Diaria</p>
                </div>
                <div class="col-md-3">
                  <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha inicio"
                    tooltipPosition="top" [(ngModel)]="fec_init_reporte2">
                  <hr class="my-0">
                </div>
                <div class="col-md-3">
                  <input type="date" class="form-control sinborde" pTooltip="Seleccione o teclee fecha fin"
                    tooltipPosition="top" [(ngModel)]="fec_fin_reporte2">
                  <hr class="my-0">
                </div>
                <div class="col-md-2">

                  <button type="button" class="btn btn-outline-success" style="width: 100%;"
                    (click)="exportarExcelReporteNuevo(fec_init_reporte2,fec_fin_reporte2)" [disabled]="fec_init_reporte2==null">
                    Exportar <i class="pi pi-file-export"></i>
                  </button>
                </div>
            </div>
            <div class="row d-flex justify-content-center">
                <div class="col-2 text-center">
                    <i class="pi pi-spin pi-spinner" *ngIf="loading_reporteOperacionDiariaMigrado"></i>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="cancelarModalReportes();"></button>
    </ng-template>
</p-dialog>
<p-dialog [resizable]="false" [draggable]="false" [(visible)]="dgfechas"
[style]="{'width': '72vw','height':'70vh'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
(onHide)="cancelarGestionFechas()">
    <ng-template pTemplate="header">
        Gestor de Fechas: {{desc_ruta}}
    </ng-template>
    <ng-template pTemplate="content">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-shadow sinborde mt-5">
                    <form [formGroup]="formfechas" (ngSubmit)="guardarFechas()">
                        <div class="row ml-5 mt-5">
                            <div class="col-sm-6">
                                <div class="col-sm-8 flex justify-content-between">
                                    <label for="lblligero" class="col-sm-6 col-form-label color-font text-right"
                                        style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                                        <span class="input-group-addon"> <i class="pi pi-calendar
                                            color-icon mr-3" style="font-size: 2rem; color: #3B82F6;"></i>
                                        </span>
                                        Registro Servicio</label>
                                </div>
                                <div class="col-sm-4">
                                    <div class="row" >
                                        <div class="flex justify-content-between d-flex">
                                            <div class="col">
                                                <input type="date" class="form-control sinborde" formControlName="fecha"
                                                    pTooltip="Seleccione o teclee fecha" tooltipPosition="top">
                                                <hr class="my-0">
                                            </div>
                                            <!-- <div class="col-sm-2" style="border-left:1px solid #000;height:500px"></div> -->
                                            <!-- <div class="col-mx-6 ml-4">
                                                <input type="time" class="form-control sinborde" formControlName="fecha_fin_ruta"
                                                pTooltip="Seleccione o teclee fecha" tooltipPosition="top"
                                                [(ngModel)]="fecha_dia">
                                                <hr class="my-0">
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col mt-6 mb-5 ml-5 d-resp">
                            <div class="col-sm-6 ml-5">
                                <div class="col-sm-12 flex justify-content-between">
                                    <label for="lblligero" class="col-sm-12 col-form-label color-font text-right"
                                        style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                                        <span class="input-group-addon"> <i class="pi pi-truck color-icon mr-3"
                                                style="font-size: 2rem; color: #3B82F6;"></i>
                                        </span>
                                        Fin Combustible</label>
                                </div>
                                <div class="col d-resp">
                                    <div class="col-sm-4 ml-4">
                                        <div class="flex justify-content-between ">
                                            <div class="col">
                                                <input type="date" class="form-control sinborde" formControlName="fecha_fin_combustible"
                                                    pTooltip="Seleccione o teclee fecha" tooltipPosition="top">
                                                <hr class="my-0">
                                            </div>
                                            <!-- <div class="col-sm-2" style="border-left:1px solid #000;height:500px"></div> -->
                                            <!-- <div class="col-mx-6 ml-4">
                                                <input type="time" class="form-control sinborde" formControlName="fecha_fin_ruta"
                                                pTooltip="Seleccione o teclee fecha" tooltipPosition="top"
                                                [(ngModel)]="fecha_dia">
                                                <hr class="my-0">
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 ml-5 mt-2">
                                <div class="col-sm-12 flex justify-content-between">
                                    <label for="lblligero" class="col-sm-12 col-form-label color-font text-right"
                                        style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                                        <span class="input-group-addon"> <i class="pi pi-building color-icon mr-3"
                                                style="font-size: 2rem; color: #3B82F6;"></i>
                                        </span>
                                        Fin Casetas</label>
                                </div>
                                <div class="col-sm-4">
                                    <div class="row ml-6" >
                                        <div class="flex justify-content-between">
                                            <div class="col">
                                                <input type="date" class="form-control sinborde" formControlName="fecha_fin_casetas"
                                                    pTooltip="Seleccione o teclee fecha" tooltipPosition="top">
                                                <hr class="my-0">
                                            </div>
                                            <!-- <div class="col-sm-2" style="border-left:1px solid #000;height:500px"></div> -->
                                            <!-- <div class="col-mx-6 ml-4">
                                                <input type="time" class="form-control sinborde" formControlName="fecha_fin_ruta"
                                                pTooltip="Seleccione o teclee fecha" tooltipPosition="top"
                                                [(ngModel)]="fecha_dia">
                                                <hr class="my-0">
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 ml-5 mt-2" >
                                <div class="col-sm-12 flex justify-content-between">
                                    <label for="lblligero" class="col-sm-12 col-form-label color-font text-right"
                                        style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                                        <span class="input-group-addon"> <i class="pi pi-check-circle color-icon mr-3"
                                                style="font-size: 2rem; color: #3B82F6;"></i>
                                        </span>
                                    Fin Ruta</label>
                                </div>
                                <div class="col-sm-4">
                                    <div class="row ml-8" >
                                        <div class="flex justify-content-between">
                                            <div class="col-mx-6">
                                                <input type="date" class="form-control sinborde" formControlName="fecha_fin_ruta"
                                                    pTooltip="Seleccione o teclee fecha" tooltipPosition="top">
                                                <hr class="my-0">
                                            </div>
                                            <!-- <div class="col-sm-2" style="border-left:1px solid #000;height:500px"></div> -->
                                            <!-- <div class="col-mx-6 ml-4">
                                                <input type="time" class="form-control sinborde" formControlName="fecha_fin_ruta"
                                                pTooltip="Seleccione o teclee fecha" tooltipPosition="top"
                                                [(ngModel)]="fecha_dia">
                                                <hr class="my-0">
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center mb-2">
                            <div class="col-2 ">
                                <button type="submit" class="btn btn-success w-100"
                                [disabled]="!formfechas.valid || !formfechas.dirty"
                                    pTooltip="Modificar Fechas" tooltipPosition="left">
                                    <span class="text-white">Guardar Todo</span>
                                    <i class="pi pi-save text-white"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- <div class="row d-flex justify-content-center">
                <div class="col-2 text-center">
                    <i class="pi pi-spin pi-spinner" *ngIf="loading_fechas"></i>
                </div>
            </div> -->
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="cancelarGestionFechas();"></button>
    </ng-template>
</p-dialog>
</div>
