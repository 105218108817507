import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { IDefaultGD, IDefaultGDTUT, IDefaultGDLess, IDefaultDataUpdateCheck, IEquiposSeg } from '../models/defaultGD';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class GestionDispositivosToolsUnitTransportService {
  private apiUrl: string ='';
  constructor(private http: HttpClient) {
    this.apiUrl = environment.sUrlGd + 'tool';
  }

  getListadoHerramientasPorUnidadAll(): Observable<IDefaultGDTUT> {
    return this.http
      .get<IDefaultGDTUT>(this.apiUrl + '/unit/all')
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoHerramientasPorUnidad(id: String): Observable<IDefaultGDTUT> {
    return this.http
      .get<IDefaultGDTUT>(this.apiUrl + '/unit/by?id=' + id)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoHerramientasPorUnidadSoloNumeroEconomico(): Observable<IDefaultGDLess> {
    return this.http
      .get<IDefaultGDLess>(this.apiUrl + '/unit/less')
      .pipe(retry(1), catchError(this.handleError));
  }

  getRptListadoHerramientasPorUnidad(): Observable<any> {
    return this.http
      .get<any>(this.apiUrl + '/unit/reportx',{responseType:'blob' as 'json'})
      .pipe(retry(1), catchError(this.handleError));
  }

  getRptControlDispositivos(): Observable<any> {
    return this.http
      .get<any>(this.apiUrl + '/unit/report/1',{responseType:'blob' as 'json'})
      .pipe(retry(1), catchError(this.handleError));
  }

  getRptTablaDinamicaDispositivos(): Observable<any> {
    return this.http
      .get<any>(this.apiUrl + '/unit/report/2',{responseType:'blob' as 'json'})
      .pipe(retry(1), catchError(this.handleError));
  }

  public updateActualizarHerramientaInstalada(data: IDefaultDataUpdateCheck ):Observable<IDefaultGD>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.put<any>(this.apiUrl + '/unit/installed', body,{'headers':headers});
   }

   public updateActualizarHerramientaConfigurada(data: IDefaultDataUpdateCheck ):Observable<IDefaultGD>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.put<any>(this.apiUrl + '/unit/configured', body,{'headers':headers});
   }

   public updateActualizarHerramientaFuncionando(data: IDefaultDataUpdateCheck ):Observable<IDefaultGD>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.put<any>(this.apiUrl + '/unit/working', body,{'headers':headers});
   }

   public saveFechasSegHerramienta(data: IEquiposSeg):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.put<any>(this.apiUrl + '/unit/devices/info', body,{'headers':headers});
   }

   public getReporteFechasSegHerramienta(data: String):Observable<any>{
    const headers = { 'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'};

    return this.http.get<any>(this.apiUrl + '/unit/report-units',{ params: new HttpParams().
        set('id',data.toString()),observe: 'response', responseType: 'blob' as 'json' })
      .pipe(retry(1), catchError(this.handleError));
   }

   public getReporteFechasSegHerramientaHeaders(data: String):Observable<any>{
    const headers = { 'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'};
    return this.http.get<Blob>(this.apiUrl + '/unit/report-units?id='+data,{ observe: 'response', responseType: 'blob' as 'json' });
   }
  // public getReporteFechasSegHerramienta(data: String):Observable<Object[]> {
  //   const headers = { 'content-type': 'application/json'};
  //   const valores = {
  //       params: new HttpParams().
  //         set('id',data.toString())
  //     };
  //   //return this.http.get<any>(this.apiUrl + '/unit/report-units',valores).pipe(retry(1), catchError(this.handleError));
  //   return Observable.create(observer => {
  //     let xhr = new XMLHttpRequest();
  //     xhr.open('GET', this.apiUrl + '/unit/report-units'+valores, true);
  //     xhr.setRequestHeader('Content-type', 'application/json');
  //     xhr.responseType='blob';

  //     xhr.onreadystatechange = function () {
  //         if (xhr.readyState === 4) {
  //             if (xhr.status === 200) {

  //                 var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  //                 var blob = new Blob([xhr.response], { type: contentType });
  //                 observer.next(blob);
  //                 observer.complete();
  //             } else {
  //                 observer.error(xhr.response);
  //             }
  //         }
  //     }
  //     xhr.send();

  //    });
  //  }

  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}
