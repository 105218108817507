

import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { IRuta } from '../models/ruta';
import { RutaService } from '../services/ruta.service';
import { AuthService } from '../services/auth.service';
import ICredencialUsuario from '../models/credencial';
import { IEstatus } from '../models/estatus';
import { IMes } from '../models/mes';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IHistoricoFoliosDeProyecto } from '../models/historico_folios';
import { IDataGuardarFolio } from '../models/data_folio';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { IFolioMensual } from '../models/FolioMensual';
import { IrptFolioRutaMensual } from '../models/rptFolioMensual';
import { saveAs } from 'file-saver';
import { FolioUnidadesService } from '../services/foliounidades.service';
import { IFolioUnidad } from '../models/foliounidades';
import { CiudadService } from '../services/ciudad.service';
import { ICiudad } from '../models/ciudad';
import { ConfunidadService } from '../services/confunidad.service';
import { IConfiguracionUnidad } from '../models/confUnidad';
import { Ifolios } from '../models/foliosunidadesindividuales';
import { find } from 'rxjs';
import { IUnidadessinfolio } from '../models/unidadesinfolio';

@Component({
  selector: 'app-foliounidades',
  templateUrl: './foliounidades.component.html',
  styleUrls: ['./foliounidades.component.scss']
})
export class FoliounidadesComponent {

  @ViewChild('dt1') dt!: Table;
  @ViewChild('dt2') dt2!: Table;
  lstRutas: IRuta[] = [];
  loading: boolean = false;
  /*Colores*/
  color_rojo_especial: string = '#FF0000';
  color_gris_deshabilitadas: string = '#D9D9D9';
  //Registro
  user: ICredencialUsuario = {};
  lstEstatus: IEstatus[] = [{
    id: 1,
    nombre: 'ACTIVA'
  },
  {
    id: 0,
    nombre: 'INACTIVA'
  }];

  //PARA EL Agregar Folio
  dsbAgregarfolio: boolean = false;
  desc_num: string = '';
  meses: IMes[] = [
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];
  selectedMes: IMes = {
    num_mes: -1,
    nombre: ""
  }
  REGEX_CARACTERES: any =/^(?=(?:.*[A-Za-z]){2})(?=(?:.*[0-9]){2})(?=(?:.*[@$?¡\-_]){1})\S{6,9}$/;;
  foliosForm: FormGroup = new FormGroup({
    idx: new FormControl(0, [Validators.required]),
    id_unidad_transporte : new FormControl(0, [Validators.required]),
    num_economico: new FormControl({ value: 0, disabled: true }, [Validators.required]),
    num_folio_proyecto: new FormControl('',[Validators.required, Validators.pattern(this.REGEX_CARACTERES)]),
    num_mes: new FormControl(0, [Validators.required]),
    num_anio: new FormControl(0, [Validators.required])
  });
  habilitar_edicion_costo: boolean = true;
  //
  dsbgestionfolios: boolean = false;
  anio: any;
  clv_operacion:number=0;
  lstHistorico: IHistoricoFoliosDeProyecto[]=[];
  loadinglst:boolean=false;
  //
  dsbAgregarHerramientasEditar:boolean=false;
  //reporte 1
  dbsReportes:boolean=false;
  selectedMesReporte1: IMes = {
    num_mes: -1,
    nombre: ""
  }
  lstunidadesEstatus = [
    { id: 0, nombre: 'Inactiva' },
    { id: 1, nombre: 'Activa' },
    { id: 3, nombre: 'Baja de Operación' },
    { id: 4, nombre: 'Activa con Reporte' }
  ];

  anioReporte1: any;
  lstFolioMensual:IFolioMensual[]=[];
  lstRptFolioMensual: IrptFolioRutaMensual[]=[];
  loading_reporte:boolean=false;
  constructor(
    private rutaService: RutaService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private folioUnidadesService: FolioUnidadesService,
    private auth: AuthService,
    private ciudadService: CiudadService,
    private confUnidadService: ConfunidadService,) {
    this.cargarInformacionUsuario();
    this.getListaDeCiudades();
    this.cargarListadoConfUnidad();
  }

  ngOnInit(): void {
    this.getFolioUnidades();
  }

  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMes.nombre = mes.nombre;
        this.selectedMes.num_mes = mes.num_mes;
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
      }
    });
    this.anio = getYear;
    this.anioReporte1 = getYear;
    this.anio_valid = getYear;
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }




  lstCiudades: ICiudad[] = [];
  lstConfUnidad: IConfiguracionUnidad[] = [];

  getListaDeCiudades() {
    this.ciudadService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudades = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Listar Ciudades>', detail: 'Contacte al Administrador del Sitio' });
      });
  }



  cargarListadoConfUnidad() {
    this.confUnidadService.getListadoConfUnidades().subscribe((resp) => {
      this.lstConfUnidad = resp;
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en cargar <Conf. de Unidad>', detail: 'Contacte al Administrador del Sitio' });
      });
  }



  mostrarModalAgregarFolio(data: IFolioUnidad) {


    this.deshabilitarTodos();
    this.desc_num = data.num_economico;
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    this.folioUnidadesService.getFolioUnidadesindividuales(data.num_economico, data.id_unidad_transporte).subscribe(
        (resp) => {
            this.lstfolios = resp;
            setTimeout(() => {
                this.loading = false;
            }, 800);
            let lstfol = this.lstfolios.find(x => x.num_anio === parseInt(getYear) && x.num_mes === parseInt(getMonth));
            this.inicializarFechas();
            this.foliosForm.controls['idx'].setValue(0);
            this.foliosForm.controls['num_mes'].setValue(this.selectedMes.num_mes);
            this.foliosForm.controls['num_anio'].setValue(this.anio);
            this.foliosForm.controls['num_economico'].setValue(data.num_economico);
            this.foliosForm.controls['num_folio_proyecto'].setValue(lstfol?.num_folio_proyecto || '');
            this.foliosForm.controls['id_unidad_transporte'].setValue(data.id_unidad_transporte);

            this.dsbAgregarfolio = true;
        },
        (error) => {
            this.messageService.add({severity: 'error', summary: 'Error al obtener los Folios de Proyecto Mensual',detail: 'Contacte al Administrador del Sitio'
            });
        }
    );
}


  cancelarModalAgregarFolio() {
    this.dsbAgregarfolio = false;
    this.resetearFormulario();
    this.desc_num = '';
    this.habilitar_edicion_costo = true;
  }

  habilitarEdicionFolios() {
    this.habilitarTodos();
    this.habilitar_edicion_costo = false;
  }

  habilitarEdicionFolios_historico() {
    this.foliosForm.get('num_folio_proyecto')!.enable();
    this.habilitar_edicion_costo = false;
  }

  deshabilitarTodos(){
    this.foliosForm.get('num_mes')!.disable();
    this.foliosForm.get('num_anio')!.disable();
    this.foliosForm.get('num_folio_proyecto')!.disable();
  }

  habilitarTodos(){
    this.foliosForm.get('num_mes')!.enable();
    this.foliosForm.get('num_anio')!.enable();
    this.foliosForm.get('num_folio_proyecto')!.enable();
  }




  lstfolios: Ifolios[] = [];
  gestionarFoliosDeRuta(data: IFolioUnidad) {
  this.lstfolios=[]
    this.loading=true;
    this.folioUnidadesService.getFolioUnidadesindividuales(data.num_economico, data.id_unidad_transporte).subscribe((resp)=> {
    this.lstfolios = resp;
    setTimeout(() => {
      this.loading = false;
     this.desc_num = data.num_economico;
     this.dsbgestionfolios = true;
    },
      300);
    },

    (error) => {
      this.loading = false;
      this.messageService.add({ severity: 'error', summary: 'Error al obtener los<Folio de Proyecto Mensual> ', detail: 'Contacte al Administrador del Sitio' });
    })




  }

  cancelarModalHistoricoFolios() {
    this.lstHistorico=[];
    this.desc_num = '';
    this.dsbgestionfolios = false;
  }



  guardarInformacionFolio() {
    this,this.loading=true;
    let anio_cast: any = 0;
    if (this.foliosForm.get('num_anio')?.value instanceof Date) {

      anio_cast = this.foliosForm.get('num_anio')?.value.getFullYear();
    } else {
      anio_cast = this.foliosForm.get('num_anio')?.value;
    }
    let data_enviar = {
      id_unidad_transporte: this.foliosForm.controls['id_unidad_transporte'].value,
      num_folio_proyecto: this.foliosForm.get('num_folio_proyecto')?.value,
      num_economico: this.foliosForm.get('num_economico')?.value,
      num_mes: this.foliosForm.get('num_mes')?.value,
      num_anio: anio_cast,
      cod_usuario: this.user.cod!,
    };

    this.folioUnidadesService.guardarFolioRuta(data_enviar).subscribe((resp)=> {


      if(resp.clv_estatus==1){
        this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: resp.messageDetails });
        this.cancelarModalAgregarFolio();
       // this.loading=false;
      }
      if(resp.clv_estatus===-2){
        this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Advertencia', detail: resp.messageDetails });


      }
      setTimeout(() => {
        this.loading=false;
      }, 500);
    },
    (error)=> {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Guardar Folio del Mes>', detail: 'Contacte al Administrador del Sitio' });
      this.loading=false;
    });
  }

  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  mostrarModalEditarFolio(data:Ifolios){
    this.habilitar_edicion_costo=true;
    this.foliosForm.controls['idx'].setValue(data.idx);
    this.foliosForm.controls['num_mes'].setValue(data.num_mes);
    this.foliosForm.controls['num_anio'].setValue(data.num_anio);
    this.foliosForm.controls['num_folio_proyecto'].setValue(data.num_folio_proyecto);
    this.foliosForm.get('num_mes')!.disable();
    this.foliosForm.get('num_anio')!.disable();
    this.foliosForm.get('num_folio_proyecto')!.disable();

    this.dsbAgregarHerramientasEditar = true;
  }

  cancelarModalEditarFolio(){
    this.dsbAgregarHerramientasEditar = false;
    this.dsbgestionfolios = false;

    this.habilitar_edicion_costo=true;
    this.resetearFormulario();
    //
    this.foliosForm.get('num_mes')!.enable();
    this.foliosForm.get('num_anio')!.enable();
  }

  resetearFormulario(){
    this.foliosForm.controls['idx'].setValue(0);
    this.foliosForm.controls['id_unidad_transporte'].setValue(0);
    this.foliosForm.controls['num_mes'].setValue(0);
    this.foliosForm.controls['num_anio'].setValue(0);
    this.foliosForm.controls['num_folio_proyecto'].setValue('');

  }

  guardarInformacionFolioEditar(){
    this.loadinglst=true;
    let data_enviar  ={
      idx: this.foliosForm.get('idx')?.value,
      id_unidad_transporte:this.foliosForm.controls['id_unidad_transporte'].value,
      num_folio_proyecto: this.foliosForm.get('num_folio_proyecto')?.value,
      num_economico: this.foliosForm.get('num_economico')?.value,
      num_mes: this.foliosForm.get('num_mes')?.value,
      num_anio: this.foliosForm.get('num_anio')?.value,
      cod_usuario: this.user.cod!,
    };
    this.folioUnidadesService.guardarFolioRutaeditar(data_enviar).subscribe((resp)=> {
      setTimeout(() => {
        if(resp.clv_estatus===1){
          this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: resp.messageDetails });
          this.cancelarModalAgregarFolio();

        }
        if(resp.clv_estatus===-2){
          this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Advertencia', detail: resp.messageDetails });
        }
      },
        300);

      this.cancelarModalEditarFolio();
      this.getFolioUnidades();
      this.loadinglst=false;
    },
    (error)=> {
      this.loadinglst=false;
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Editar Folio de Ruta>', detail: 'Contacte al Administrador del Sitio' });
    });
  }




  lstFolioUnidad: IFolioUnidad[]=[]
  getFolioUnidades() {

    this.loading = true;
    this.folioUnidadesService.getFolioUnidades().subscribe((resp) => {
      this.lstFolioUnidad = resp;
      setTimeout(() => {
        this.loading = false;
      },
        800);

    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de RUTAS');
        this.loading = false;
      });
  }

  dbsvalidacion:boolean =false
  anio_valid:any ;

  validacionmen(){
    this.inicializarFechas();
    this.dbsvalidacion =true


  }

  deshabilitartabla :boolean=false;
num_registros:number =0;
  lstUnidadessinfolio:IUnidadessinfolio[]=[];
  getvalidacionfolios() {
    this.lstUnidadessinfolio=[];

    if (!this.anio_valid || this.selectedMesReporte1.num_mes === -1) {
      this.mostrarDialogoInformativoSinInformacion('Mes y Año deben seleccionarse para consultar información.');
      this.loading_reporte = false;
    } else {

      this.folioUnidadesService.getvalidacionsinfolios(this.anio_valid, this.selectedMesReporte1.num_mes).subscribe(
        (resp) => {
          this.lstUnidadessinfolio = resp;


          // Verificamos si tenemos resultados para habilitar la tabla
          if (this.lstUnidadessinfolio.length > 0) {
            this.deshabilitartabla = true;
            this.num_registros= this.lstUnidadessinfolio.length
            let var1 = resp.length

          } else {
            this.deshabilitartabla = false;
          }
        },
        (error) => {
          // En caso de error
          this.loading_reporte = false;
          this.messageService.add({
            key: 'msj',
            severity: 'error',
            summary: 'Error en <ReporteFoliosProyectoMensual>',
            detail: 'Contacte al Administrador del Sitio',
          });
        }
      );
    }
  }


  cancelarvalidacionmen(){
    this.dbsvalidacion = false;
    this.anio_valid = 0
    this.selectedMesReporte1.num_mes = 0
    this.deshabilitartabla =false;
    this.lstUnidadessinfolio=[]
    this.num_registros =0
  }




  generarReporte1() {
   this.loading_reporte = true;
    this.lstFolioMensual = [];
    this.lstRptFolioMensual = [];
    if ((this.selectedMesReporte1 == null || this.selectedMesReporte1.num_mes == -1) || (this.anioReporte1 === 'undefined' || this.anioReporte1 == null)) {
      this.mostrarMensajeDeAlerta();
      //this.mostrarDialogoInformativoSinInformacion('Mes y Año deben seleccionarse para consultar informacion.');
      this.loading_reporte = false;
    } else {
      let mes = this.selectedMesReporte1.num_mes;
      let anio_cast: any = 0;
      if (this.anioReporte1 instanceof Date) {
        // will execute
        anio_cast = this.anioReporte1.getFullYear();
      } else {
        anio_cast = this.anioReporte1;
      }
      this.cargarInformacionDelMes( mes, anio_cast);
    }
  }

  mostrarMensajeDeAlerta() {
    this.loading = false;
    this.messageService.add({
      key: 'confirm',
      sticky: true,
      severity: 'info',
      summary: 'Falta Mes y/o Año',
      detail: 'Mes y Año deben seleccionarse para consultar informacion.'
    })
  }

  cancelarMensajeConfirmacion(){
    this.messageService.clear('confirm');
  }

  confirmarMensaje(){
    this.messageService.clear('confirm');
  }

  cargarInformacionDelMes( num_mes: number, num_anio: number) {
    this.folioUnidadesService.getListadoFolioMensual(num_mes, num_anio).subscribe((resp :any  ) => {



      if(resp.length === 0 || typeof resp === 'undefined')  {
        this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
      } else {
        import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(resp);
          const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
          const excelBuffer: any = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array"
          });
          this.saveAsExcelFile(excelBuffer, "Folios_de_Unidades" + '_' + this.selectedMesReporte1.nombre + '_' + num_anio);
        });
      }
      this.loading_reporte = false;
    },
      (error) => {
        this.loading_reporte = false;
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <ReporteFoliosProyectoMensual>', detail: 'Contacte al Administrador del Sitio' });
      });
  }



  abrirModalReportes() {
    this.inicializarFechas();
    this.dbsReportes = true;
  }


  cancelarModalReportes() {
    this.dbsReportes = false;
    this.selectedMes = {
      num_mes: 0,
      nombre: ''
    }
    this.anio = null;

    this.selectedMesReporte1 = {
      num_mes: 0,
      nombre: ''
    }
    this.anioReporte1 = null;
  }



  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + EXCEL_EXTENSION);

  }



  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
    /*Filtro General para la informacion de tabla*/
  applyFilterGlobal2($event: any, stringVal: any) {
    this.dt2.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }


  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }
}
